/****************************************************************************************************/
/*This file will control the javascript client side aspect of the whole front end of the application.*/
/*It will be included within the <head></head> of the the application which will facilitate caching  */
/*of the file preventing multiple http requests for javascript files. On each page of the app a      */
/*hidden field (<input type="hidden" value="page-name" name="c_page" />) will be included which will */
/*be used for recognizing which page the user is currently on. This initialization is done within    */
/*the document.ready function and the variable cPage is used to maintain which page the user is      */
/*currently on. Each Page will have it's designated set of fields which will be cached for optimized */
/*and efficient javascript and jquery functionality.                                                */
/*****************************************************************************************************/

/******** Main JS Variables***********************************************/
/*All variables and methods that will be common to all pages will be     */
/*declared here 	 													 */
/*************************************************************************/
/* This is now being set in /frontend/protected/views/site/main.php in the registerClientScript call */
var ajaxLocation = "/", 
	pageUrl = null,
	validator  = null,
	mainNavigation = null,
	pageLoaded = false,
	baseSearchDiv = null,
	mainSearchLink = null,
	bedroomBathroomDiv = null,
	getTypesAjaxRequest = "",
	propertyTypeList = null,
	propertyTypeTab = null,
	bedBathLabelDiv = null,
	bedBathInputDiv = null,
	resetButton = null,
	resCom = null,
	keywordsText = null,
	bedList = null,
	bathList = null,
	priceFromText = null,
	priceToText = null,
	resComRadio = null,
	allCurrencyRadio = null,
	rentSaleRadio = null,
	twitterSlide = null,
	twitterUpdateList = null,
	apiLoginAjaxRequest = "",
	emailAjaxRequest = "",
	getAjaxRequest = "",
	getCurrentPage = null,
	getPaginationDiv = null,
	getPaginationUl = null,
	mainContainer = null,
	logoutLink = null,
	spamFilter = null,
	point,
	map,
	marker,
	myOptions,
	Zoom,
	lattitudeField = null,
	longitudeField = null,
	popStateCheck;

$(document).ready(function(){	



	cPage = $('input[name="c_page"]').val();



	$('input, textarea').placeholder();

	if($('.fancybox-media').length){
		$('.fancybox-media').fancybox({
			openEffect  : 'none',
			closeEffect : 'none',
			helpers : {
				media : {}
			}
		});
	}
	
	if(cPage != 'index'){
		$('body').addClass('inside_page');
	}




	$( "#social_icons li a" ).on( "mouseenter", function() {
    	$('#social_icons li a').style('opcaity', '0.15 !important');
    	$(this).style('opacity', '1');
	}).on( "mouseleave", function() {
	   $('#social_icons li a').style('opcaity', '1 !important');
	});


	getTypesAjaxRequest 	= "";	
	propertyTypeList 		= $("#property_type" );
	baseSearchDiv 			= $("#base_search" );
	priceFromText 			= $("input[name='price_from']");
	priceToText 				= $("input[name='price_to']");
	priceRangeSelect 		= $("select[name='price']");
		
	rentSaleType					= $('#rent_sale_tab');
	rentSaleRadioName 		= $("input[name='rentsale']");
	
	parishList 						= $('#parish');
	townList 							= $('#town');
	
	keywordsText 				= $("#keywords");
	bedList 						= $("#bed");
	bathList 						= $("#bath");
	bedroomBathroomDiv 	= $('#bedroom_bathroom_div');
	idSearch 						= $("#id_search" );
	cPage 								= $('input[name="c_page"]').val();	
	furnishedUnfirnished 	= $("input[name='furnished']");
	pageCheck();

	propertyTypeTab 						= $('#property_type_tab');
	residentialCommercialRadio 	= $('input[id="rescom"]');
	
	if(propertyTypeTab.length > 0){
		if(cPage !== "search" && cPage !== "details" && cPage !== "print" && cPage !== "sign_up"){
			searchResidentialCommercialChange(propertyTypeTab.val()); 
		}
	}
	
	if($('.featured_slider').length){
		
		$('.featured_slider').bxSlider({
			pager: false,
			auto: ($(".featured_slider li").length > 4) ? true: false,
			autoHover: true,
			pause:16000,
			minSlides: 4,
			maxSlides: 4,
			slideWidth: 253,
			slideMargin: 2,
			nextSelector: '#featured-next',
			prevSelector: '#featured-prev',
			nextText: '<i class="fa fa-chevron-right"></i>',
			prevText: '<i class="fa fa-chevron-left"></i>'
		});	
		
	}
	
	if($('.banner_slider').length){
		$('.banner_slider').bxSlider({
			pager: false,
			auto: ($(".banner_slider li").length > 1) ? true: false,
			autoHover: true,
			pause:16000,
		});	
	}
	
	if($('.front_slider').length){
		$('.front_slider').bxSlider({
			auto: ($(".front_slider li").length > 1) ? true: false,
			controls:false,
			speed: 5600,
			pause: 10500,
			pager: false,
			autoHover: true,
			mode: 'fade'
		});
	}
	
	if($('.featured_properties').length){
		$('.featured_properties').bxSlider({
			auto: ($(".featured_properties li").length > 1) ? true: false,
			infiniteLoop: true,
			touchEnabled: true,
			controls: true,
			autoHover: true,
			speed: 5600,
			pause: 10500,
			pager: false
		});
	}
	

	if($('.property_details_slider').length){
		var detailsSlider =  $('.property_details_slider').bxSlider({
			pager:false,
			auto: true,
			onSlideAfter: function(){
			    // do funky JS stuff here
			    var slideQty = detailsSlider.getSlideCount();
			    var currentSlide = detailsSlider.getCurrentSlide();
			    
			    $('#current_val').text(currentSlide+1);
			    $('#total_val').text(slideQty);
			}
		});	
		var slideQty = detailsSlider.getSlideCount();		
		var currentSlide = detailsSlider.getCurrentSlide();
			
		$('#current_val').text(currentSlide+1);
		$('#total_val').text(slideQty);
	}
	
	rentSaleType.delegate('dd a', 'click', function(evt) {
		evt.preventDefault();
		var linkClick = $(this);
		switch(linkClick.html()){
			case 'Rent':
				rentSaleRadioName.val('rent');
			break;
			case 'Sale':
				rentSaleRadioName.val('sale');
			break;
			default:
				rentSaleRadioName.val('');
			break;
		}
		
	});
	
	propertyTypeTab.delegate('dd a', 'click', function(evt) {
		evt.preventDefault();	
		var linkClick = $(this);		
		switch(linkClick.html()){
			case 'Residential':
				residentialCommercialRadio.val('residential');
				searchResidentialCommercialChange(residentialCommercialRadio.val());
			break;
			case 'Commercial':
				residentialCommercialRadio.val('commercial');
				searchResidentialCommercialChange(residentialCommercialRadio.val());
			break;
			default:
				residentialCommercialRadio.val('');
				searchResidentialCommercialChange('');
			break;
		}
	});
	
	parishList.change(function(){
		var typesUrl = ajaxLocation+"ajax/resource/parish_town/"+parishList.val();
		if(getTypesAjaxRequest !== ""){
			getTypesAjaxRequest.abort();
		}
		getTypesAjaxRequest = $.ajax({
			type:'GET',
			url: typesUrl,
			dataType: "html"
		});
		getTypesAjaxRequest.then(
			function(data){	
				townList.html(data);
			},
			function(data){
			}
		);
	});
	
	$('#curr').change(function(){
		priceFromText.val('');
		priceToText.val('');
		var selectedRadio = $(rentSaleRadioName.selector + ":checked").val();
		var typesUrl = ajaxLocation+"ajax/resource/price_range/"+selectedRadio;
		if($(this).val() !== "" ){
			typesUrl += "/currency/" + $('#curr').val();
		}
		if(getTypesAjaxRequest !== ""){
			getTypesAjaxRequest.abort();
		}
		getTypesAjaxRequest = $.ajax({
			type:'GET',
			url: typesUrl,
			dataType: "html"
		});
		getTypesAjaxRequest.then(
			function(data){		
			
				$('#price').html(data)			
			},
			function(data){				
				
			}
		);
	});
	

	rentSaleType.delegate('dd a', 'click', function() {
		priceFromText.val('');
		priceToText.val('');
		var typesUrl = ajaxLocation+"ajax/resource/price_range/"+$(this).html();
		if($('#curr').val() !== "" ){
			typesUrl += "/currency/" + $('#curr').val();
		}
		if(getTypesAjaxRequest !== ""){
			getTypesAjaxRequest.abort();
		}
		getTypesAjaxRequest = $.ajax({
			type:'GET',
			url: typesUrl,
			dataType: "html"
		});
		getTypesAjaxRequest.then(
			function(data){		
						
				$('#price').html(data)			
			},
			function(data){				
				
			}
		);
	});

	
	
	priceRangeSelect.delegate(priceRangeSelect, 'change', function(evt) {
		evt.preventDefault();
		searchPriceselected(priceRangeSelect.val());
	});
	
	resetForm();		
	searchPop();
	configureFacebookAPI();
	
});

function searchPop(){
	
	$(document).on('click', '#search_action', function(e) {
		var currStatus = $(this).data("status");
		
		if(currStatus == 'hide'){
			$(".search_options_form").addClass('moved');
			$(".featuredContent").addClass('moved');	
			$(this).data('status', 'show');
		}else{
			$(".search_options_form").removeClass('moved');
			$(".featuredContent").removeClass('moved');	
			$(this).data('status', 'hide');
		}
			
	});
}

function resetForm(){
	var resetButton 			= $('#main_search #reset_button');
	var residentialRadio 	= $('#main_search #residential');
	var rentSaleRadio 		= $('#main_search #rentsaleboth');
	var rentSaleSelect 		= $('#main_search #rentsale');
	var categoryRadio 		= $('#main_search #all_radio');
	var currencyRadio 		= $('#main_search #currAll');
	var currencySelect 		= $('#main_search #curr');
	var parishSelect 		  = $('#main_search #parish');
	var rescomInput				= $('#main_search #rescom');
	var propertyTypeList 		= $("#property_type" );
	
	resetButton.click(function(e){
		e.preventDefault();
		priceFromText.val("");	
		priceToText.val("");	
		priceToText.val("");	
		priceRangeSelect.val("");
		
		propertyTypeList.val("");	
		keywordsText.val("");
		bedList.val("");
		bathList.val("");				
		parishSelect.val('');
		residentialRadio.prop("checked", true);
		rescomInput.val("");
		rentSaleRadio.prop("checked", true);
		rentSaleSelect.val("both");
		
		currencyRadio.prop("checked", true);
		currencySelect.val('all');
		propertyTypeList.val('');
		categoryRadio.prop("checked", true);
		furnishedUnfirnished.prop("checked", false);
		idSearch.val('');
		searchResidentialCommercialChange(residentialRadio.val());
	});	
}

function PropertiesMultiple(){
	if(getAjaxRequest != ''){
		getAjaxRequest.abort();
	}
	var width = $(document).width();

	
	if($('.featured_properties').length > 0){
		if(width > 640){
			featSlide = $(".featured_properties").bxSlider({
				auto: true,
				controls: ($(".featured_properties li").length > 4) ? true: false,
				speed: 1800,
				pause: 12000,
				pager: false,
				minSlides: 4,
				maxSlides: 4,
				slideWidth: 250,
				slideMargin: 10,
				autoHover: true,
				preloadImages: 'visible',
				nextSelector: '#mFeaturednext',
				prevSelector: '#mFeaturedprev',
				nextText: 'Onward →',
				prevText: '← Go back'
			});
	}else{
			featSlide = $(".featured_properties").bxSlider({
				auto: true,
				controls: ($(".featured_properties li").length > 1) ? true: false,
				speed: 1800,
				pause: 12000,
				pager: false,
				minSlides: 1,
				maxSlides: 1,
				autoHover: true,
				preloadImages: 'visible',
				nextSelector: '#mFeaturednext',
				prevSelector: '#mFeaturedprev',
				nextText: 'Onward →',
				prevText: '← Go back'
			});
		}
	}
	$(document).on('click', '.featured_grouping li a', function(e) {
			e.preventDefault();
			
			var property_type = $(this).data("proptype");
			var groupType 		= $('.featured_grouping');
			searchLoadingDiv 	= $('.featured_loading');
			mainContainer 		= $('.featured_container');
			searchStartStopLoading(true);
			
			$('.featured_grouping li').removeClass('active');
			$(this).parent().addClass('active');
			
			var featuredUrl =  ajaxLocation+"ajax-featured-property/"+property_type;
	
			getAjaxRequest = $.ajax({
													type:'POST',
													url: featuredUrl,
													dataType: "html",									
												});
			getAjaxRequest.then(
				function(data){
					mainContainer.html('');	
					mainContainer.html(data);	
					searchStartStopLoading(false);
					
					var width = $(document).width();
					if($('.featured_properties').length > 0){
						if(width > 640){
							featSlide = $(".featured_properties").bxSlider({
								auto: true,
								controls: ($(".featured_properties li").length > 4) ? true: false,
								speed: 1800,
								pause: 12000,
								pager: false,
								minSlides: 4,
								maxSlides: 4,
								slideWidth: 250,
								slideMargin: 10,
								autoHover: true,
								preloadImages: 'visible',
								nextSelector: '#mFeaturednext',
								prevSelector: '#mFeaturedprev',
								nextText: 'Onward →',
								prevText: '← Go back'
							});
					}else{
							featSlide = $(".featured_properties").bxSlider({
								auto: true,
								controls: ($(".featured_properties li").length > 1) ? true: false,
								speed: 1800,
								pause: 12000,
								pager: false,
								minSlides: 1,
								maxSlides: 1,
								autoHover: true,
								preloadImages: 'visible',
								nextSelector: '#mFeaturednext',
								prevSelector: '#mFeaturedprev',
								nextText: 'Onward →',
								prevText: '← Go back'
							});
						}
					}
					
				},
				function(data){
					
				}
			); 
			
		});	
	
	if($('.featured_block').length > 0){
		$('#mFeaturednext').html('');
		$('#mFeaturedprev').html('');
		featSlide.reloadSlider();		
	}
	
}
   
   
/*****************************************************************************************************/
/*Change The property type list based on residential or commercial selection within the search form. */
/*****************************************************************************************************/
function searchResidentialCommercialChange(value){	
	value = value.toLowerCase();	
	switch(value){
		case 'residential':	
			bedroomBathroomDiv.show();		
		break;
		case 'commercial':
			bedroomBathroomDiv.hide();
			bedroomBathroomDiv.children().children().each(function(){
				$(this).val('');
			});		
		break;	
		default:
			value = "";
			bedroomBathroomDiv.hide();
			bedroomBathroomDiv.children().children().each(function(){
				bedroomBathroomDiv.show();	
			});	
		break;
	}	
	var typesUrl = ajaxLocation+"ajax/resource/propertytypes/"+value;
	if(getTypesAjaxRequest !== ""){
		getTypesAjaxRequest.abort();
	}
	getTypesAjaxRequest = $.ajax({
							type:'GET',
							url: typesUrl,
							dataType: "html"
						});
	getTypesAjaxRequest.then(
		function(data){				
			propertyTypeList.html(data)			
		},
		function(data){
		}
	); 
}

function stickyNav(){
	var width = $(window).width();
	var height = $(window).height();
		
	if(parseInt(width) >= 1024){
		if($('.nav').length){
		var stickyNavTop = $('.nav').offset().top;
		var stickyNav = function(){  
			var scrollTop = $(window).scrollTop();
			if (scrollTop > stickyNavTop) {   
				$('.nav').addClass('fixed2');  
			} else {  
				$('.nav').removeClass('fixed2');   
			}  
		};  
		stickyNav();  
		$(window).scroll(function() {  
			stickyNav();  
		}); 
	}
	}
}

$(window).resize(function() {
	stickyNav();
});

function isEmptyObject(obj) {
	var name;
	for (name in obj) {
		return false;
	}
	return true;
}

function apiLogin(obj) {
	//searchStartStopLoading(true);
	apiLoginAjaxRequest = $.ajax({
									type:'POST',
									url: ajaxLocation + 'member',
									dataType: "json",
									data: obj,
								}
							);
	apiLoginAjaxRequest.then(
		function(data){		
			console.log(ajaxLocation+ 'member');	
			console.log(data);
			if(data.status === 200){
				window.location.href = window.location.href;
				/*searchStartStopLoading(false);				
				
				*/
			}
			else{
				/*
				$('#modal_header').html("Failed");
				$('#modal_paragraph').html(obj.sent_api + " Login Failed, Please Try Again or Refresh The Page");
				$('#modal_button').val("Ok");
				$('#member_modal').foundation('reveal', 'open');
				*/
			}		
		},
		function(data){		
			console.log(ajaxLocation+ 'member');
			console.log(data);
			/*searchStartStopLoading(false);
			$('#modal_header').html("Failed");
			$('#modal_paragraph').html(obj.sent_api + " Login Failed, Please Try Again or Refresh The Page");
			$('#modal_button').val("Ok");
			$('#member_modal').foundation('reveal', 'open');
			*/
		}

	);	
}

/**************************************************************************/
/*This method is used for managing the history state of the browser from a*/
/*Javascript aspect. Based on the page the user is currently on, the      */
/*method will trigger the method used to manage events based on back and  */
/*forward browser button clicks for that specific page.					  */
/**************************************************************************/
onpopstate = function(event) {	
	if(pageLoaded){
		switch(cPage){
			case 'index':
			//configureIndex();
			break;
			case 'search':			
			popStateSearchPage(event.state);			
			break;			
			case 'developments':			
			popStateDevelopmentPage(event.state);
			break;
			case 'agents':				
			popStateAgentPage(event.state);
			break;
			case 'tips':
			popStateTipsPage(event.state);
			break;	
			case 'news':
			popStateNewsPage(event.state);
			break;
			case 'favourite':
			popStateFavouritePage(event.state);
			break;
			case 'projects':
			popStateProjectsPage(event.state);
			break;
		}
	}
	pageLoaded = true;
	popStateCheck = 1;	
}
/*************************************************************************/
/*Function used to check which page the user is currently on. Based on   */
/*the current page, a configure function will be triggered which will    */
/*initialize all variables exclsuive to that page.						 */
/*************************************************************************/
function pageCheck(){
	searchPage(cPage);
	switch(cPage){
		case 'index':
			configureIndex();
			configureSearchFormElements();
			break;
		case 'search':	
			configureSearchFormElements();	
			searchColor();	
			configureSearch();
			break;
		case 'contact':		
			searchColor();	
			configureContact();
			break;
		case 'jamaica':		
			configureJamaica();
			break;
		case 'developments':
			searchColor();			
			configureDevelopments();
			break;		
		case 'agents':		
			searchColor();
			configureAgents();
			break;	
		case 'details':		
			searchColor();					
			configureDetails();	
			img_loop();			
			break;
		case 'pages':
			searchColor();
			break;	
		case 'list-property':
			searchColor();
			configureListMyProperty();
			break;	
		case 'find-property':
		configureFindProperty();
		break;
		case 'member':
			configureMembers();
		break;
		case 'sign_up':
			configureSignUp();
		break;	
		case 'forget':
			configureForgetPassword();
		break;
		case 'reset_password':
			configureResetPassword();
		break;
		case 'profile':
			configureEditProfile();
		break;	
		case 'favourite':
			configureFavouriteProperties();
			
		break;
		case 'projects':
			configureProjects();
		break;
		case 'print':
			configurePrint();
		break;
		case 'unsubscribe':
		configureUnsubscribe();
		break;
		case 'update_profile':
		configureUpdateProfile();
		break;
	}
}

function searchColor(){
	$(".red_bar").hide();
	$("#search_field").addClass("red");
}

function configurePrint(){
	var spamFilter2 = null,
		spamFilter3 = null
	
	
	var slider = $('.report_property_slider').bxSlider({
		pager:false,
		auto: ($(".report_property_slider li").length > 1) ? false: false ,
		controls: ($(".report_property_slider li").length > 1) ? true: false,
		speed: 1590,
		pause: 10000,
		preloadImages : 'all',
		useCSS: false,
		easing: 'linear',
		onSlideAfter: function(){
			// do funky JS stuff here
		  var slideQty = slider.getSlideCount();
		  var currentSlide = slider.getCurrentSlide();
		
		  $('#current_val').text(currentSlide+1);
		  $('#total_val').text(slideQty);
		 }	            
	});
	var slideQty = slider.getSlideCount();
	var currentSlide = slider.getCurrentSlide();
	
	 $('#current_val').text(currentSlide+1);
	 $('#total_val').text(slideQty);
	
	 $('.bx-prev , .bx-next, .bx-pager-item').on('click',function(){
	 	slider.stopAuto();
	  	slider.startAuto();            
	 });
	
	$(".various1").fancybox({
		autoSize	: true,
		closeClick	: false,
		openEffect	: 'none',
		closeEffect	: 'none'
	});

	$('#print_page').click(function(){
		window.print();
	});
	
	locationLink 		= $('#location_link');
	locationSpan		= $('#location_span');
	imageDiv 				= $('#image_div');
	mapDiv 					= $('#mapping_canvas');	
	lattitudeField 	= $('input[name="lattitude"]');
	longitudeField 	= $('input[name="longitude"]');
	Zoom						= 14;
	
	locationLink.click(function(e){
		e.preventDefault();		
		if(locationLink.data('hide') === 0){
			mapDiv.show();
			if(mapStatus === 0){
				initialize();
				mapStatus = 1;
			}
			imageDiv.hide();		
			locationSpan.html('Hide Map');
			locationLink.data('hide',1);
		}
		else if(locationLink.data('hide') === 1){
			locationLink.data('hide',0);
			mapDiv.hide();
			imageDiv.show();
			locationSpan.html('Map');						
		}
	});
	
	if($('#share_form').length > 0){
	
		spamFilter 			= $('#email_address_sent');
		spamFilter2 		= $('#email_address_sent2');
		spamFilter3 		= $('#email_address_sent3');
		
		shareForm = $('#share_form');
		shareValidator = shareForm.validate();
		shareForm.removeAttr("novalidate");	
		shareFirstName 	= $('#ShareForm_firstName');	
		shareLastName 	= $('#ShareForm_lastName');	
		shareEmail 		= $('#ShareForm_emailAddress');
		shareFriendFullName = $('#ShareForm_friendsName');
		shareFriendEmail = $('#ShareForm_friendsEmail');
		shareComment = $('#ShareForm_comment');
			/*shareFullName.rules("add",{
				required:true,
				 messages: {
			            required: "Please Enter Your Full Name"
			     }
			});*/
			
		shareEmail.rules("add",{
				required:true,
				 email:"required",
				 messages: {
			           email: "Please Enter A Valid Email Address",
			           required:"Please Enter Your Email Address"
			     }
			});
		shareFriendFullName.rules("add",{
				required:true,
				 messages: {
			            required: "Please Enter Your Friend's Full Name"
			     }
			});
		shareFriendEmail.rules("add",{
				required:true,
				 email:"required",
				 messages: {
			           email: "Please Enter A Valid Email Address",
			           required:"Please Enter Your Friend's Email Address"
			     }
			});
		shareComment.rules("add",{
				required:true,
				 messages: {
			            required: "Please Enter A Comment"
			     }
			});  
			
		$(document).on('click', '#share_submit', function(e) {
			if(shareForm.valid() && spamFilter2.val() === ""){				
				sendContact('Friend');
				e.preventDefault();
			}
		});
	}
}

/**************************************************************************/
/*Property Photo Gallery												  */
/**************************************************************************/
function img_loop() {	
	var property_list_string = '';
	var propertyArray = [];
	
    $('.property_details_slider li').each(function() {
		propertyArray.push({
      		href: $(this).data('img-src')
   		});        
    });
    if (propertyArray.length === 0) {

	}else{
		$("#photo_link, #photo_link2").click(function(evt) {
		evt.preventDefault();
		
		$.fancybox.open( propertyArray , {
				helpers : {
					thumbs : {
						width: 75,
						height: 50
					},
					overlay : {
						locked: false,
						css : {
							'background' : 'rgba(0, 0, 0, 0.95)'
						}
					}		
				},
				type: "image",
				beforeShow: function () {
					var newWidth = this.width * 2;
					var newHeight = this.height * 2;
					
					$(".fancybox-image").css({
						"width": newWidth,
						"height": newHeight
					});
					this.width = newWidth;
					this.height = newHeight;
				}
			});
		});	
	}
}

function initialize() {	
	/*
	var jamaica = new google.maps.LatLng(18.081112, -77.426147);
	var lattitudeArray = lattitudeField;
	var longitudeArray = longitudeField;
	var locationArray = new Array();	
	if(listField !== null){
		listField.each(function(){
			locationArray.push($(this).val());
		});
	}	
	if(Zoom == null){
		Zoom = 8;
	}
	
	if( (longitudeArray.length === 1 && lattitudeArray.length === 1 ) ){
		jamaica = new google.maps.LatLng(lattitudeField.val(), longitudeField.val());	
		if(Zoom == null){
			if(longitudeArray.length > 1){
				Zoom = 8;
			}else{
				Zoom = 18;	
			}
			
		}
	}
	
	
	myOptions = {
		zoom: Zoom,
		center: jamaica,
		mapTypeId: google.maps.MapTypeId.ROADMAP
	}	
  
  map = new google.maps.Map(document.getElementById("mapping_canvas"), myOptions);
	for (var i = 0; i < longitudeArray.length; i++) {		
		var marker = new google.maps.Marker({
			position: new google.maps.LatLng(lattitudeArray.eq(i).val(),longitudeArray.eq(i).val()),
			map: map,
			title: 'Marker'
		});
		if(listField !== null){
			var infoWindow = new google.maps.InfoWindow(), marker, i;
    		google.maps.event.addListener(marker, 'click', (function(marker, i) {
    			return function() {
    				infoWindow.setContent(locationArray[i]);
    				infoWindow.open(map, marker);
    			}
    		})(marker, i));
    	}
    }
    */
}

/**************************************************************************/
/*Send Contact Method Used On the Property Details And Contact Us Page	  */
/**************************************************************************/
function sendContact(from,slug){
	var contactUrl = ajaxLocation;	
	switch(from){
		case 'Contact':
		searchStartStopLoading(true);
		contactUrl = window.location.href;
		emailAjaxRequest = $.ajax({
									type:'POST',
									url: contactUrl,
									dataType: "json",
									data: contactForm.serializeArray(),
								}
							);
		emailAjaxRequest.then(
			function(data){		
				if(parseInt(data.status) === 200){
					$('#modal_header').html("Sent");
					$('#modal_paragraph').html(data.message);
					$('#modal_button').val("Ok");
					$('#contact_modal').removeClass('failed');
				}
				else{
					$('#modal_header').html("Failed");
					$('#modal_paragraph').html(data.message);
					$('#modal_button').val("Try Again");
					$('#contact_modal').addClass('failed');
				}					
				searchStartStopLoading(false);
				$('#contact_modal').foundation('reveal', 'open');				
				$('html,body').animate({scrollTop: $('body').offset().top}, 500);
			},
			function(data){		
				
				$('#modal_header').html("Failed");
				$('#modal_paragraph').html(data.message);
				$('#modal_button').val("Try Again");
				$('#contact_modal').addClass('failed');
								
				searchStartStopLoading(false);		
				$('#contact_modal').foundation('reveal', 'open');
				$('html,body').animate({scrollTop: $('body').offset().top}, 500);
			}
		); 
		break;	
		case 'Contact Index':
		searchStartStopLoading(true);
		contactUrl = window.location.href;
		emailAjaxRequest = $.ajax({
									type:'POST',
									url: contactUrl,
									dataType: "json",
									data: contactForm.serializeArray(),
								}
							);
		emailAjaxRequest.then(
			function(data){		
				if(parseInt(data.status) === 200){
					$('#modal_header').html("Sent");
					$('#modal_paragraph').html(data.message);
					$('#modal_button').val("Ok");
					$('#contact_modal').removeClass('failed');
				}
				else{
					$('#modal_header').html("Failed");
					$('#modal_paragraph').html(data.message);
					$('#modal_button').val("Try Again");
					$('#contact_modal').addClass('failed');
				}					
				searchStartStopLoading(false);
				$('#contact_modal').foundation('reveal', 'open');				
				//$('html,body').animate({scrollTop: $('body').offset().top}, 500);
			},
			function(data){		
				
				$('#modal_header').html("Failed");
				$('#modal_paragraph').html(data.message);
				$('#modal_button').val("Try Again");
				$('#contact_modal').addClass('failed');
								
				searchStartStopLoading(false);		
				$('#contact_modal').foundation('reveal', 'open');
				//$('html,body').animate({scrollTop: $('body').offset().top}, 500);
			}
		); 
		break;
		case 'Contact Search Agent':
			mainPropertyContainer.fadeOut('fast');
			searchLoadingDiv.fadeIn('slow');
			
			contactUrl = window.location.href;			
			emailAjaxRequest = $.ajax({
										type:'POST',
										url: contactUrl,
										dataType: "json",
										data:  agentForm2.serializeArray(),
									}
								);
			emailAjaxRequest.then(
				function(data){		
					
					if(parseInt(data.status) === 200){
						agentForm2.trigger("reset");;
						$('#modal_header').html("Sent");
						$('#modal_paragraph').html(data.message);
						$('#modal_button').val("Ok");
						$('#details_modal').removeClass('failed');
					}
					else{
						$('#modal_header').html("Failed");
						$('#modal_paragraph').html(data.message);
						$('#modal_button').val("Try Again");
						$('#details_modal').addClass('failed');
					}	
					$('#details_modal').foundation('reveal', 'open');
					//$('html,body').animate({scrollTop: $('body').offset().top}, 500);
					
					
					searchLoadingDiv.fadeOut('fast');
					mainPropertyContainer.fadeIn('slow');
				},
				function(data){
					
					$('#modal_header').html("Failed");
					$('#modal_paragraph').html(data.message);
					$('#modal_button').val("Try Again");
					$('#details_modal').addClass('failed');
					
					$('#details_modal').foundation('reveal', 'open');
					//$('html,body').animate({scrollTop: $('body').offset().top}, 500);
					searchLoadingDiv.fadeOut('fast');
					mainPropertyContainer.fadeIn('slow');
				}
			);
			
		break;	
		case 'Agent':
		searchStartStopLoading(true);
		contactUrl = window.location.href;		
		emailAjaxRequest = $.ajax({
									type:'POST',
									url: contactUrl,
									dataType: "json",
									data:  agentForm.serializeArray(),
								}
							);
		emailAjaxRequest.then(
			function(data){	
			
				if(parseInt(data.status) === 200){
					agentForm.trigger("reset");;
					$('#modal_header').html("Sent");
					$('#modal_paragraph').html(data.message);
					$('#modal_button').val("Ok");
					$('#details_modal').removeClass('failed');
				}
				else{
					$('#modal_header').html("Failed");
					$('#modal_paragraph').html(data.message);
					$('#modal_button').val("Try Again");
					$('#details_modal').addClass('failed');
				}	
				$('#details_modal').foundation('reveal', 'open');
				$('html,body').animate({scrollTop: $('body').offset().top}, 500);
				searchStartStopLoading(false);
			},
			function(data){
				
				$('#modal_header').html("Failed");
				$('#modal_paragraph').html(data.message);
				$('#modal_button').val("Try Again");
				$('#details_modal').addClass('failed');
				
				$('#details_modal').foundation('reveal', 'open');
				$('html,body').animate({scrollTop: $('body').offset().top}, 500);
				searchStartStopLoading(false);
			}
		);

		break;
		case 'Friend':
		
		searchStartStopLoading(true);		
		contactUrl = window.location.href;		
		emailAjaxRequest = $.ajax({
									type:'POST',
									url: contactUrl,
									dataType: "json",
									data:  shareForm.serializeArray(),
								}
							);
		emailAjaxRequest.then(
			function(data){		
			
				if(parseInt(data.status) === 200){
					shareForm.trigger("reset");;
					$('#modal_header').html("Sent");
					$('#modal_paragraph').html(data.message);
					$('#modal_button').val("Ok");	
					$('#details_modal').removeClass('failed');				
				}
				else{
					$('#modal_header').html("Failed");
					$('#modal_paragraph').html(data.message);
					$('#modal_button').val("Try Again");
					$('#details_modal').addClass('failed');
				}	
				$('#details_modal').foundation('reveal', 'open');
				$('html,body').animate({scrollTop: $('body').offset().top}, 500);
				searchStartStopLoading(false);
			},
			function(data){
				
				$('#modal_header').html("Failed");
				$('#modal_paragraph').html(data.message);
				$('#modal_button').val("Try Again");
				$('#details_modal').addClass('failed');
				
				$('#details_modal').foundation('reveal', 'open');
				$('html,body').animate({scrollTop: $('body').offset().top}, 500);
				searchStartStopLoading(false);	
			}
		);		
		break;
		case 'Search Agent':
		$('#contact_pop').foundation('reveal', 'close');	
		mainPropertyContainer.fadeOut('fast');
		searchLoadingDiv.fadeIn('slow');
		contactUrl = ajaxLocation + 'property/' + slug;	
		emailAjaxRequest = $.ajax({
									type:'POST',
									url: contactUrl,
									dataType: "json",
									data:  agentForm.serializeArray(),
								}
							);
		emailAjaxRequest.then(
			function(data){		
				
				if(parseInt(data.status) === 200){
					agentForm.trigger("reset");;
					$('#modal_header').html("Sent");
					$('#modal_paragraph').html(data.message);
					$('#modal_button').val("Ok");
					$('#details_modal').removeClass('failed');
				}
				else{
					$('#modal_header').html("Failed");
					$('#modal_paragraph').html(data.message);
					$('#modal_button').val("Try Again");
					$('#details_modal').addClass('failed');
				}	
				$('#details_modal').foundation('reveal', 'open');
				//$('html,body').animate({scrollTop: $('body').offset().top}, 500);
				searchLoadingDiv.fadeOut('fast');
				mainPropertyContainer.fadeIn('slow');
			},
			function(data){		
				
				$('#modal_header').html("Failed");
				$('#modal_paragraph').html(data.message);
				$('#modal_button').val("Try Again");
				$('#details_modal').addClass('failed');
				
				$('#details_modal').foundation('reveal', 'open');
				//$('html,body').animate({scrollTop: $('body').offset().top}, 500);
				searchLoadingDiv.fadeOut('fast');
				mainPropertyContainer.fadeIn('slow');
			}
		);

		break;
		case 'Search Friend':	
		$('#share_pop').foundation('reveal', 'close');	
		mainPropertyContainer.fadeOut('fast');
		searchLoadingDiv.fadeIn('slow');	
		contactUrl = ajaxLocation + 'property/' + slug;		
		emailAjaxRequest = $.ajax({
									type:'POST',
									url: contactUrl,
									dataType: "json",
									data:  shareForm.serializeArray(),
								}
							);
		emailAjaxRequest.then(
			function(data){			
			
				if(parseInt(data.status) === 200){
					shareForm.trigger("reset");;
					$('#modal_header').html("Sent");
					$('#modal_paragraph').html(data.message);
					$('#modal_button').val("Ok");	
					$('#details_modal').removeClass('failed');				
				}
				else{
					$('#modal_header').html("Failed");
					$('#modal_paragraph').html(data.message);
					$('#modal_button').val("Try Again");
					$('#details_modal').addClass('failed');
				}	
				$('#details_modal').foundation('reveal', 'open');
				// $('html,body').animate({scrollTop: $('body').offset().top}, 500);
				
				searchLoadingDiv.fadeOut('fast');
				mainPropertyContainer.fadeIn('slow');
				//getAjaxRequest = propertySearchRefine(searchLimit,searchRange,getCurrentPage,getAjaxRequest,false);
			},
			function(data){
				
				$('#modal_header').html("Failed");
				$('#modal_paragraph').html(data.message);
				$('#modal_button').val("Try Again");
				$('#details_modal').addClass('failed');
				$('#share_pop').foundation('reveal', 'close');
				$('#details_modal').foundation('reveal', 'open');
				// $('html,body').animate({scrollTop: $('body').offset().top}, 500);
				searchLoadingDiv.fadeOut('fast');
				mainPropertyContainer.fadeIn('slow');
				//getAjaxRequest = propertySearchRefine(searchLimit,searchRange,getCurrentPage,getAjaxRequest,false);
			}
		);		
		break;
		case 'Request':
		
		searchStartStopLoading(true);
		contactUrl = window.location.href;
		emailAjaxRequest = $.ajax({
									type:'POST',
									url: contactUrl,
									dataType: "json",
									data:  requestForm.serializeArray(),
								}
							);
		emailAjaxRequest.then(
			function(data){		
				if(parseInt(data.status) === 200){
					requestForm.trigger("reset");
					$('#modal_header').html("Sent");
					$('#modal_paragraph').html(data.message);
					$('#modal_button').val("Ok");
					$('#details_modal').removeClass('failed');
				}else{
					$('#modal_header').html("Failed");
					$('#modal_paragraph').html(data.message);
					$('#modal_button').val("Try Again");
					$('#details_modal').addClass('failed');
				}	
				$('#details_modal').foundation('reveal', 'open');
				$('html,body').animate({scrollTop: $('body').offset().top}, 500);
				searchStartStopLoading(false);
			},
			function(data){
				
				$('#modal_header').html("Failed");
				$('#modal_paragraph').html(data.message);
				$('#modal_button').val("Try Again");
				$('#details_modal').addClass('failed');
				$('#details_modal').foundation('reveal', 'open');
				$('html,body').animate({scrollTop: $('body').offset().top}, 500);
				searchStartStopLoading(false);	
			}
		);	
		break;
		case 'List':
		searchStartStopLoading(true);
		contactUrl = window.location.href;
		emailAjaxRequest = $.ajax({
									type:'POST',
									url: contactUrl,
									dataType: "json",
									data:  listForm.serializeArray(),
								}
							);
		emailAjaxRequest.then(
			function(data){		
				
				if(parseInt(data.status) === 200){
					$('#modal_header').html("Sent");
					$('#modal_paragraph').html(data.message);
					$('#modal_button').val("Ok");
					$('#list_modal').removeClass('failed');
				}
				else{
					$('#modal_header').html("Failed");
					$('#modal_paragraph').html(data.message);
					$('#modal_button').val("Try Again");
					$('#list_modal').addClass('failed');
				}	
				$('#list_modal').foundation('reveal', 'open');
				$('html,body').animate({scrollTop: $('body').offset().top}, 500);
				
				searchStartStopLoading(false);
			},
			function(data){
				
				$('#modal_header').html("Failed");
				$('#modal_paragraph').html(data.message);
				$('#modal_button').val("Try Again");
				$('#list_modal').addClass('failed');
				
				$('#list_modal').foundation('reveal', 'open');
				$('html,body').animate({scrollTop: $('body').offset().top}, 500);
				searchStartStopLoading(false);	
			}
		);	
		break;
		case 'Find':
		searchStartStopLoading(true);
		contactUrl = window.location.href;
		emailAjaxRequest = $.ajax({
									type:'POST',
									url: contactUrl,
									dataType: "json",
									data:  findForm.serializeArray(),
								}
							);
		emailAjaxRequest.then(
			function(data){	
				
				if(parseInt(data.status) === 200){
					$('#modal_header').html("Sent");
					$('#modal_paragraph').html(data.message);
					$('#modal_button').val("Ok");
					$('#find_modal').removeClass('failed');
				}
				else{
					$('#modal_header').html("Failed");
					$('#modal_paragraph').html(data.message);
					$('#modal_button').val("Try Again");
					$('#find_modal').addClass('failed');
				}	
				$('#find_modal').foundation('reveal', 'open');
				$('html,body').animate({scrollTop: $('body').offset().top}, 500);
				searchStartStopLoading(false);
			},
			function(data){
				
				$('#modal_header').html("Failed");
				$('#modal_paragraph').html(data.message);
				$('#modal_button').val("Try Again");
				$('#list_modal').addClass('failed');
				
				$('#find_modal').foundation('reveal', 'open');
				$('html,body').animate({scrollTop: $('body').offset().top}, 500);
				searchStartStopLoading(false);
			}
		);	
		break;
		
		
		default :
		break;
	}	
}

/**************************************************************************/
/*This method initializes the loading process and uninitializes the same  */
/*process. If the parameter is true, then it initializes else it          */
/*uninitializes the process.											  */
/**************************************************************************/
function searchStartStopLoading(startStop){
	if(startStop){
		if(mainPropertyContainer !== null){
			mainPropertyContainer.html('&nbsp;');	
			mainPropertyContainer.fadeOut('fast');
		}		
		if(mainContainer !== null){
			mainContainer.fadeOut('fast');	
		}		
		searchLoadingDiv.fadeIn('slow');
	}
	else{		
		searchLoadingDiv.fadeOut('fast');	
		if(mainPropertyContainer !== null){
			mainPropertyContainer.fadeIn('slow');
		}		
		if(mainContainer !== null){
			mainContainer.fadeIn('slow');	
		}		
	}		
}

function configurePagination(){	
	getPaginationDiv = $('.pagination');
	getPaginationUl = $('.pagination .ul_pager');
	
	getPaginationUl.delegate('li a', 'click', function(evt) {		
		evt.preventDefault();		
     	switch($(this).html().trim()){
     		case '‹ First':
     		getCurrentPage = 1;     		
     		break;
     		case '« Previous':
     		if(getCurrentPage !== 1 && getCurrentPage !== 0){
     			getCurrentPage--;
     		}     		
     		break;
     		case 'Next ›':
     		if(getCurrentPage !== parseInt($('input[name="number_pages"]').val())){
     			getCurrentPage++;
     		}  
     		break;
     		case 'Last »':
     		getCurrentPage = parseInt($('input[name="number_pages"]').val());
     		break;
     		default:
     		getCurrentPage = parseInt($(this).html().trim());
     		break;     		
     	} 
     	switch(cPage){     		
			case 'search':		
			getAjaxRequest = propertySearchRefine(searchLimit,searchRange,getCurrentPage,getAjaxRequest,false);						
			break;
			case 'developments':
			getAjaxRequest = developmentPagination(getCurrentPage,getAjaxRequest,false);		
			break;		
			case 'agents':
			getAjaxRequest = agentsPagination(getCurrentPage,getAjaxRequest,false);						
			break;	
			case 'news':
			getAjaxRequest = newsPagination(getCurrentPage,getAjaxRequest,false);
			break;
			case 'tips':
			getAjaxRequest = tipsPagination(getCurrentPage,getAjaxRequest,false);
			break;	
			case 'favourite':
			getAjaxRequest = favouritePagination(getCurrentPage,getAjaxRequest,false);
			break;
			case 'projects':
			getAjaxRequest = projectsPagination(getCurrentPage,getAjaxRequest,false);
			break;
		}     	
    });
}

function formatNumber(num, decpoint, sep) {
	if (arguments.length == 2) {
		sep = ",";
	}
	if (arguments.length == 1) {
		sep = ",";
		decpoint = ".";
	}

	num = num.toString();  
  	a = num.split(decpoint);
  	x = a[0];
  	y = a[1];
  	z = "";
  	if (typeof(x) != "undefined") {
  		for (i=x.length-1;i>=0;i--)
  			z += x.charAt(i);
  		z = z.replace(/(\d{3})/g, "$1" + sep);
  		if (z.slice(-sep.length) == sep)
  			z = z.slice(0, -sep.length);
  		x = "";
  		for (i=z.length-1;i>=0;i--)
  			x += z.charAt(i);
  		if (typeof(y) != "undefined" && y.length > 0)
  			x += decpoint + y;
  	}
  	return x;
}

/******************Search Form Configuration METHOD***********************/
function searchPage(cPage){	
	$('.search_class').removeClass('inner');
	$('.search_class').removeClass('front');
	if(cPage != 'index' && cPage != null){
		$('.search_class').addClass('inner');
	}else{
		$('.search_class').addClass('front');
	}	
}

function configureSearchFormElements(){	

	priceRangeSelect 		= $("select[name='price']");

	resetButton 				= $('#reset_button');
	resCom 							= $('#rescom');

	parishList 					= $('#parish');
	townList 						= $('#town');

	propertyTypeList 		= $("#property_type");
	propertyTypeTab 		= $('#property_type_tab');
	bedBathLabelDiv 		= $('#bedroom_bathroom_label_div');
	bedBathInputDiv 		= $('#bedroom_bathroom_input_div');
	bedroomBathroomDiv	= $('#bedroom_bathroom_input_div');
	
	keywordsText 			= $('#keywords');
	bedList 					= $('#bed');
	bathList 					= $('#bath');
	priceFromText 		= $('#residential_price_range_from');
	priceToText 			= $('#residential_price_range_to');
	resComRadio 			= $('#rescom');
	allCurrencyRadio 	= $("#currAll");
	rentSaleRadio 		= $("#rentsale");

	propertyTypeTab.delegate('dd a', 'click', function(evt) {
		evt.preventDefault();
		var linkClick = $(this);
		var typeCall = null;

		switch(linkClick.html()){
			case 'Residential':
				resCom.val('Residential');
				typeCall = "residential";
				rentSaleRadio.val('both');
				searchResidentialCommercialChange(typeCall);
			break;
			case 'Commercial':
				resCom.val('Commercial');
				typeCall = "commercial";
				rentSaleRadio.val('both');
				searchResidentialCommercialChange(typeCall);
			break;
			case 'Short Term Rental':
				rentSaleRadio.val('short');
				resCom.val('Residential');
				typeCall = "residential";
				
				searchResidentialCommercialChange(typeCall);
				$('#property_type_tab dd:first-child').addClass('active');
			break;
			case 'Foreclosure':
				resCom.val('foreclosure');
				typeCall = "";
				rentSaleRadio.val('foreclosure');
				searchResidentialCommercialChange(typeCall);
			break;
			case 'Private Treaty':
				resCom.val('private');
				typeCall = "";
				rentSaleRadio.val('private');
				searchResidentialCommercialChange(typeCall);
			break;
		}		
	});	
	resetButton.click(function(e){		
		e.preventDefault();
		propertyTypeTab.children().removeClass('active');
		bedBathLabelDiv.show();
		bedBathInputDiv.show();
		resCom.val('');
		keywordsText.val('');
		propertyTypeList.val('');
		bedList.val('');
		bathList.val('');
		priceFromText.val('');
		priceToText.val('');	
		resComRadio.val('');
		parishList.val('');
		townList.val('');
		$('#price').val('');
		$('#curr').val('all');
		allCurrencyRadio.prop("checked", true);	
		rentSaleRadio.val('both');
	});
	
	parishList.change(function(){
		var typesUrl = ajaxLocation+"ajax/resource/parish_town/"+parishList.val();
		if(getTypesAjaxRequest !== ""){
			getTypesAjaxRequest.abort();
		}
		getTypesAjaxRequest = $.ajax({
			type:'GET',
			url: typesUrl,
			dataType: "html"
		});
		getTypesAjaxRequest.then(
			function(data){	
				townList.html(data);
			},
			function(data){
			}
		);
	});

	$('#curr').change(function(){
		priceFromText.val('');
		priceToText.val('');
		var selectedRadio = rentSaleRadio.val();
		var typesUrl = ajaxLocation+"ajax/resource/price_range/"+selectedRadio;
		if($(this).val() !== "" ){
			typesUrl += "/currency/" + $('#curr').val();
		}
		if(getTypesAjaxRequest !== ""){
			getTypesAjaxRequest.abort();
		}
		getTypesAjaxRequest = $.ajax({
			type:'GET',
			url: typesUrl,
			dataType: "html"
		});
		getTypesAjaxRequest.then(
			function(data){		
			
				$('#price').html(data)			
			},
			function(data){				
				
			}
		);
	});

	rentSaleRadio.change(function(){
		if($(this).val() == 'short'){
			$('#short_panel').click();
		}
		
		priceFromText.val('');
		priceToText.val('');
		var selectedRadio = rentSaleRadio.val();
		var typesUrl = ajaxLocation+"ajax/resource/price_range/"+selectedRadio;
		if($(this).val() !== "" ){
			typesUrl += "/currency/" + $('#curr').val();
		}
		if(getTypesAjaxRequest !== ""){
			getTypesAjaxRequest.abort();
		}
		getTypesAjaxRequest = $.ajax({
			type:'GET',
			url: typesUrl,
			dataType: "html"
		});
		getTypesAjaxRequest.then(
			function(data){		
			
				$('#price').html(data)			
			},
			function(data){				
				
			}
		);
	});

	priceRangeSelect.delegate(priceRangeSelect, 'change', function(evt) {
		evt.preventDefault();
		searchPriceselected(priceRangeSelect.val());
	});

}
function searchPriceselected(value){
	priceFromText 		= $("input[name='price_from']");
	priceToText 			= $("input[name='price_to']");
	
	priceFromText.val('');
	priceToText.val('');
	
	switch(value){
		case '0-5':	
			priceFromText.val('0');
			priceToText.val('5000');	
		break;
		case '5-10':	
			priceFromText.val('5000');
			priceToText.val('10000');		
		break;
		case '10-50':	
			priceFromText.val('5000');
			priceToText.val('10000');		
		break;
		case '50-100':	
			priceFromText.val('50000');
			priceToText.val('100000');			
		break;
		case '100-500':	
			priceFromText.val('100000');
			priceToText.val('500000');		
		break;
		case '500-999':	
			priceFromText.val('500000');
			priceToText.val('999000');		
		break;
		case '1-3M':	
			priceFromText.val('1000000');
			priceToText.val('3000000');		
		break;
		case '3-6M':	
			priceFromText.val('3000000');
			priceToText.val('6000000');		
		break;
		case '6-9M':	
			priceFromText.val('6000000');
			priceToText.val('9000000');		
		break;
		case '9-12M':	
			priceFromText.val('9000000');
			priceToText.val('12000000');		
		break;
		case '12-15M':	
			priceFromText.val('12000000');
			priceToText.val('15000000');	
		break;
		case '15_plus':	
			priceFromText.val('15000000');
			priceToText.val('');	
		break;
		default:	
			var arr = value.split('-');
			priceFromText.val(arr[0]);
			priceToText.val(arr[1]);
		break;
		
	}
}

/**************************TWITTER API METHODS****************************/
function getTweets(){
	
	//if($('#twitterslide').length){
		twitterSlide = $('#twitterslide');	
		
		$.get(ajaxLocation+"ajax/resource/api/twitter/include_entities/true/include_rts/true").done(function(data) {
			twitterSlide.html(data);
			listTweets(data);
		});
	//}
}  	
  	
function listTweets(data){ 
	twitterUpdateList = $('#twitter_update_list');
	obj = $.parseJSON(data);
  	var output = '';
  	
  	
  	
  	$.each(obj, function(key, val) {
  			var text = obj[key].text;
  			var textArray = text.split("http");
  			var channel_name = obj[key].user.name;
  			var screen_name = obj[key].user.screen_name;
  			var created_at = obj[key].created_at;
  			created_at = created_at.substr(4,6);
  			
  			
  			text = textArray[0];
  			text_url = "http"+textArray[1];
  			
  			
  			text=text.replace(/[A-Za-z]+:\/\/[A-Za-z0-9-]+.[A-Za-z0-9-:%&~\?\/.=]+/g, function(i){
  				var url=i.link(i);
  				return url;
  			});  				     
  			text=text.replace(/[@]+[A-Za-z0-0-]+/g,function(i) {
  				var item= i.replace("@",'');
  				var url=i.link("http://twitter.com/" + item);
  				return url;
  			});  				
  			text = text.replace(/[#]+[A-Za-z0-9-]+/g, function(i) {
  				var item=i.replace("#",'%23');
  				var url = i.link("http://search.twitter.com/search?q="+item);
  				return url;
  			});
  			  			
  			output += '<li>';
  			output += '<b>'+channel_name+'</b> @'+screen_name+' · '+created_at+' · ';
  			output += text.substr(0,55) + " · <a href='"+text_url+"'>"+text_url+"</a>";
  			output += '</li>';
  		});
  	output += '';
  	twitterUpdateList.html(output);
	twitterUpdateList.bxSlider({
		auto: true,
  		controls:false,
  		speed:5600,
  		pause:7500,
		pager: false,
		autoHover: true,
		minSlides: 3,
		maxSlides: 3,
		mode: 'vertical'
  	});
 } 
/*************************************************************************/

/*=========================================================================
 Div Effects
========================================================================= */

function divEffects(){
	
	
	(function($) {
			  
	!function(t) {
	t.fn.unveilEffect=function(n, i) {
	function e() {
	s=a.filter(function() {
	var n=t(this), i=o.scrollTop(), e=i+o.height(), f=n.offset().top, s=f+n.height();
	return s>=i-r&&e+r>=f
	}
	), f=s.trigger("unveil.effect"), a=a.not(f)
	}
	var f, s, o=t(window), r=i||0, a=this;
	return this.one("unveil.effect", n), o.scroll(e).resize(e).load(e), this
	}, unveilEffectSettings=t.extend( {
	transitionDuration:1, transitionEasing:"ease-in-out", selector:"[data-effect]", threshold:200
	}, "undefined"!=typeof unveilEffectSettings?unveilEffectSettings:!1), t(function() {
	if(t.fn.unveilEffect.transition=function() {
	var t=document.createElement("bs"), n= {
	WebkitTransition:"-webkit-transition", MozTransition:"-moz-transition", OTransition:"-o-transition", transition:"-transition"
	};
	for(var i in n)if(void 0!==t.style[i])return {
	css:n[i]
	}
	}
	(), t.fn.unveilEffect.transition) {
	var n=t(unveilEffectSettings.selector);
	n.each(function() {
	var n=t(this), i=n.data("effect");
	n.addClass("effect-"+i)[0].offsetWidth, n.css(t.fn.unveilEffect.transition.css, "all "+unveilEffectSettings.transitionDuration+"s "+unveilEffectSettings.transitionEasing)
	}
	)
	.unveilEffect(function() {
	t(this).addClass("in")
	},
	-unveilEffectSettings.threshold)
	}
	}
	)
	}
	(window.jQuery);
	
	
	})(jQuery);
}

/*************************************************************************/
/*Configures the landing page of the application.    					 */
/*************************************************************************/
function configureIndex(){	
	
	if($('.recent_properties').length){
		$('.recent_properties').bxSlider({
			pager: false,
			auto: ($(".recent_properties li").length > 4) ? true: false,
			autoHover: true,
			pause:16000,
			minSlides: 4,
			maxSlides: 4,
			slideWidth: 300,
			slideMargin: 2,
			nextSelector: '#recent-next',
			prevSelector: '#recent-prev',
			nextText: '<i class="fa fa-chevron-right"></i>',
			prevText: '<i class="fa fa-chevron-left"></i>'
		});	
	}

}

/*************************************************************************/

/********Variables && Methods Exclusive To The Contact Page **************/
var contactFirstName = null,
	contactLastName = null,
	contactCompanyName = null,
	contactEmail = null,
	contactTelephone = null,
	contactSubject = null,
	contactComment = null,
	contactHear = null,
	contactSubmit = null,
	contactForm = null,		
	listField = null;
	

/*************************************************************************/
/*Configures the contact page of the application. 				         */
/*************************************************************************/
function configureJamaica(){
	lattitudeField 	= $('input[name="latitude[]"]');
	longitudeField 	= $('input[name="longitude[]"]');
	listField 			= $('input[name="locations[]"]');
	
	
	Zoom = 8;
	initialize();
	
	
}


function configureContact(){	
	Zoom = 8;
	spamFilter = $('#email_address_sent');
	contactFirstName = $('#ContactForm_firstName');
	contactLastName = $('#ContactForm_lastName');
	contactCompanyName = $('#ContactForm_companyName');
	contactEmail = $('#ContactForm_email');
	contactTelephone = $('#ContactForm_telephone');
	contactSubject = $('#ContactForm_subject');
	contactComment = $('#ContactForm_comment');
	contactHear = $('#ContactForm_hearAboutUs');
	contactSubmit = $('#submit_contact_request');
	contactCountry = $('#ContactForm_country');
	contactLocation = $('#ContactForm_location');
	locationDiv = $('#location_div');


	contactForm = $('#contact-form');	
	validator = contactForm.validate();
	lattitudeField = $('input[name="latitude[]"]');
	longitudeField = $('input[name="longitude[]"]');
	listField = $('input[name="locations[]"]');
	searchLoadingDiv = $('#loading');
	mainContainer = $('#contact_div');




	emailAjaxRequest = "";	
	
	contactFirstName.rules("add",{
		required:true,
		 messages: {
                required: "Please Enter Your First Name"
         }
	});
	
//	contactCountry.rules("add",{
//		required:true,
//		 messages: {
//                required: "Please Select A Country"
//         }
//	});
	
	contactLastName.rules("add",{
		required:true,
		 messages: {
                required: "Please Enter Your Last Name"
         }
	});
	contactEmail.rules("add",{
		 email:"required",
		 required:true,
		 messages: {
			 email: "Please Enter A Valid Email Address",
			 required:"Please Enter Your Email Address"
        }
	});
	contactComment.rules("add",{
		required:true,
		 messages: {
                required: "Please Enter Your Message"
         }
	});
	contactHear.rules("add",{
		required:true,
		 messages: {
                required: "Please Select An Option For How You Heard Of Us"
         }
	});

//	contactCountry.change(function(){
//		if(contactCountry.val() === 'Jamaica'){
//			locationDiv.show();
//			contactLocation.addClass('required');
//			validator = contactForm.validate();
//		}
//		else{
//			locationDiv.hide();	
//			contactLocation.val('');
//			contactLocation.removeClass('required');
//		}
//	});

	contactSubmit.click(function(e){
		if(contactForm.valid() && spamFilter.val() === ""){			
			sendContact('Contact');
			e.preventDefault();
		}
	});

	$('#modal_button').click(function(){
		if($(this).val() === "Ok"){
			window.location.href = window.location.href;
		}
		else{
			$('#contact_modal').foundation('reveal', 'close');
		}
	});
	initialize();
}

/******Variables && Methods Exclusive To The Details Page ****************/
var photoLink = null,
	locationLink = null,
	imageDiv = null,
	mapDiv = null,
	mapStatus = 0,
	actionDiv = null,
	shareActionDiv = null,
	showingActionDiv = null,
	shareForm = null,
	shareValidator = null,
	shareValidator = null,
	requestValidator = null,
	requestForm = null,
	shareFriendLink = null,
	exposeMask = null,
	locationSpan = null,
	videoSpan = null,
	savePropertyLink = null,
	savedTitle = null,	
	datePicker = null,
	spamFilter2 = null,
	savePropertyAjaxRequest = "";

function configureDetails(){	
	Zoom = 16;
	
	spamFilter 			= $('#email_address_sent');
	spamFilter2 		= $('#email_address_sent2');
	spamFilter3 		= $('#email_address_sent3');
	datePicker 			= $('#RequestForm_requestDateTime');
	datePicker.datetimepicker();
	
	searchLoadingDiv 	= $('#loading');
	mainContainer 		= $('#details_div');
	photoLink 			= $('#photo_link');
	locationLink 		= $('#location_link');
	locationSpan		= $('#location_span');
	videoSpan			= $('#video_span');
	imageDiv 			= $('#image_div');
	mapDiv 				= $('#mapping_canvas');
	lattitudeField 		= $('input[name="lattitude"]');
	longitudeField 		= $('input[name="longitude"]');
	playerDiv 			= $('#main_player');
	videoLink			= $('#video_link');
	calculatorLink		= $('.mortglink');
	savePropertyLink 	= $('#save_property');
	savedTitle 			= $('#saved_title');
	saveImg 				= $('#save_property #save_image');
	shortSelect			= $('#short_rate');
	shareForm 			= $('#share_form');
	
	
	shareActionDiv 		= $('#share_action_div');
	actionDiv 				= $('#contact_action_div');
	showingActionDiv 	= $('#showing_action_div');
	
	$('.property_prices .mini').each(function(){
		var itemThis = $(this);
		var sellPrice = itemThis.data('value');
		var jmdPrice = $('#rate_value').data('jmd-monthly-price');
		
		var price = Math.round(parseInt(jmdPrice) / parseInt(sellPrice));
		price = price.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
		price = price.replace(/\.?0+$/, '');
		$('#rate_'+itemThis.data('currency')).html(itemThis.data('currency')+' $ '+price);				
	});
	
	if(shortSelect.length){
			var monthlyRate = $('input[name="monthly_rate"]').val(); 
			var weeklyRate 	= $('input[name="weekly_rate"]').val(); 
			var dailyRate 	= $('input[name="daily_rate"]').val(); 
			var jmdPrice    = '';
				
			shortSelect.change(function(e){
				var rateValue = shortSelect.val();
				if(rateValue == 'monthly'){
					$('#rate_value').html(monthlyRate);
					jmdPrice = $('#rate_value').data('jmd-monthly-price');
				}
				if(rateValue == 'weekly'){
					$('#rate_value').html(weeklyRate);
					jmdPrice = $('#rate_value').data('jmd-weekly-price');
				}
				if(rateValue == 'daily'){
					$('#rate_value').html(dailyRate);
					jmdPrice = $('#rate_value').data('jmd-daily-price');
				}
				
				$('.property_prices .mini').each(function(){
					var itemThis = $(this);
					var sellPrice = itemThis.data('value');
					
					var price = Math.round(parseInt(jmdPrice) / parseInt(sellPrice));
					price = price.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
					price = price.replace(/\.?0+$/, '');
					$('#rate_'+itemThis.data('currency')).html(itemThis.data('currency')+' $ '+price);				
				});	
							
			});
		}
		
	/********SHARING FUNCTION******/
	$(".propertySlide").bxSlider({
		pager:false
	});
	
	if($('#share_form').length > 0){
		shareValidator = shareForm.validate();
		shareActionDiv.hide();
	
		//shareFullName 	= $('#ShareForm_fullName');	
		shareFirstName 	= $('#ShareForm_firstName');	
		shareLastName 	= $('#ShareForm_lastName');	
		shareEmail 		= $('#ShareForm_emailAddress');
		shareFriendFullName = $('#ShareForm_friendsName');
		shareFriendEmail = $('#ShareForm_friendsEmail');
		shareComment = $('#ShareForm_comment');
		
		shareFirstName.rules("add",{
			required:true,
			 messages: {
		            required: "Please Enter Your First Name"
		     }
		});
		shareLastName.rules("add",{
			required:true,
			 messages: {
		            required: "Please Enter Your Last Name"
		     }
		});
		
		shareEmail.rules("add",{
			required:true,
			 email:"required",
			 messages: {
		           email: "Please Enter A Valid Email Address",
		           required:"Please Enter Your Email Address"
		     }
		});
	
		shareFriendFullName.rules("add",{
			required:true,
			 messages: {
		            required: "Please Enter Your Friend's Full Name"
		     }
		});
	
		shareFriendEmail.rules("add",{
			required:true,
			 email:"required",
			 messages: {
		           email: "Please Enter A Valid Email Address",
		           required:"Please Enter Your Friend's Email Address"
		     }
		});
		
		shareComment.rules("add",{
			required:true,
			 messages: {
		            required: "Please Enter A Comment"
		     }
		}); 
		
	
		$(document).on('click', '#share_submit', function(e) {
			if(shareForm.valid() && spamFilter2.val() === ""){				
				sendContact('Friend');
				e.preventDefault();
			}
		});
	}
	
	photoLink.click(function(e){
		e.preventDefault();
		mapDiv.hide();
		$('#main_player').css("display","none");
		imageDiv.show();
		videoLink.data('hide',0);
		locationLink.data('hide',0);
		locationSpan.html('Map');
		videoSpan.html('Video');
		//stopVideo();
	});

	locationLink.click(function(e){
		e.preventDefault();		
		if(locationLink.data('hide') === 0){
			mapDiv.show();
			if(mapStatus === 0){
				initialize();
				mapStatus = 1;
			}
			imageDiv.hide();
			playerDiv.hide();locationLink.data('hide',1);
			videoLink.data('hide',0);			
			locationSpan.html('Hide Map');
			videoSpan.html('Video');
			$('#main_player').css("display","none");
		}
		else if(locationLink.data('hide') === 1){
			locationLink.data('hide',0);
			mapDiv.hide();
			imageDiv.show();
			locationSpan.html('Map');					
			//stopVideo();	
		}
	});

	videoLink.click(function(e){		
		e.preventDefault();		
		if(videoLink.data('hide') === 0){
			$('#main_player').removeAttr("style");
			
			
			if(mapStatus === 0){
				initialize();
				mapStatus = 1;
			}
			imageDiv.hide();
			mapDiv.hide();
			videoLink.data('hide',1);
			locationLink.data('hide',0);		
			videoSpan.html('Hide Video');
			locationSpan.html('Map');			
			
			
			exposeMask.css({"position":"fixed", "top":"0","left": "0","width": "100%","height": "100%", "display": "block", "opacity":" 0.7", "z-index":"9998","background-color": "#002c48"});			
			$('#main_player').css({"position":"relative","color":"#fff","z-index":"9999"});
			
			playerDiv.show();
			callVideo();
		}
		else if(videoLink.data('hide') === 1){
			videoLink.data('hide',0);
			mapDiv.hide();
			playerDiv.hide();
			imageDiv.show();
			videoSpan.html('Video');
			stopVideo();			
		}
	});	
	
	calculatorLink.click(function(e){
		videoLink.data('hide',0);
		mapDiv.hide();
		playerDiv.hide();
		imageDiv.show();
		videoSpan.html('Video');
		stopVideo();
	});


	agentForm 		= $('#contact_agent_form');
	agentSubmit 	= $('#submit_contact');	
	
	agentFirstName 	= $('#AgentForm_firstName');
	agentLastName 	= $('#AgentForm_lastName');
	agentEmail 		= $('#AgentForm_email_address');
	agentQuery 		= $('#AgentForm_agent_query');
	agentOptions	= $('input[name="AgentForm[contact_option]"]');

	exposeMask = $('#exposeMask');
	
	contactAjaxRequest = "";
	validator = agentForm.validate();
	agentFirstName.rules("add",{
		required:true,
		 messages: {
	            required: "Please Enter Your First Name"
	     }
	});
	agentLastName.rules("add",{
		required:true,
		 messages: {
	            required: "Please Enter Your Last Name"
	     }
	});
	
	agentEmail.rules("add",{
		required:true,
		 email:"required",
		 messages: {
	           email: "Please Enter A Valid Email Address",
	           required:"Please Enter Your Email Address"
	     }
	});
	
	agentQuery.rules("add",{
		required:true,
		 messages: {
	            required: "Please Enter Your Query"
	     }
	});
	
	agentOptions.rules("add",{
		required:true,
		 messages: {
	            required: "Please Select an option"
	     }
	});
	
	agentSubmit.click(function(e){
		if(agentForm.valid() && spamFilter.val() === ""){			
			sendContact('Agent');
			e.preventDefault();
		}
	});

	savePropertyLink.click(function(e){
		e.preventDefault();
		var image_src = saveImg;
		var favouriteUrl = ajaxLocation+"ajax-favourite/property_id/"+savePropertyLink.data("property-id")+"/mls/"+savePropertyLink.data("mls")+"/saved/"+savePropertyLink.data("saved");

		savePropertyAjaxRequest = $.ajax({
									type:'POST',
									url: favouriteUrl,
									dataType: "json",									
								}
							);
		savePropertyAjaxRequest.then(
			function(data){
				if(data.status === "200"){
					if( savePropertyLink.data("saved") === 0 ){
						savePropertyLink.data("saved",1);
						savedTitle.html("Remove Favourite");
						
						image_src = image_src.html("<i class='fa fa-heart'></i>");
						image_src.addClass('red');
						
						$('#modal_header').html("Saved");
						$('#modal_paragraph').html("Favourite Was Saved Successfully");
						$('#modal_button').val("Ok");
					}
					else{
						savePropertyLink.data("saved",0);
						savedTitle.html("Save This Property");
						
						image_src = image_src.html("<i class='fa fa-heart-o'></i>");
						image_src.removeClass('red');
						
						$('#modal_header').html("Removed");
						$('#modal_paragraph').html("Favourite Was Removed Successfully");
						$('#modal_button').val("Ok");
					}
				}
				else{
					if( savePropertyLink.data("saved") === 0 ){
						$('#modal_header').html("Error");
						$('#modal_paragraph').html("Favourite Was Not Saved, Please Try Again");
						$('#modal_button').val("Ok");
					}
					else{
						$('#modal_header').html("Error");
						$('#modal_paragraph').html("Favourite Was Not Removed, Please Try Again");
						$('#modal_button').val("Ok");
					}
				}	
				$('#details_modal').foundation('reveal', 'open');	
				$('html,body').animate({scrollTop: $('body').offset().top}, 500);					
			},
			function(data){
			}
		); 
	});

	$('#modal_button').click(function(){
		$('#details_modal').foundation('reveal', 'close');
	});

	$(document).on('click', '#share_friend', function(e) {
		e.preventDefault();
		var show_value = $("#contact_action_div").data('hide');	
		
		
		if(show_value == 0){
			$("#contact_action_div").data('hide', '1');				
			$("#share_friend span").html('CONTACT AGENT');
			$('.agent_details_form').addClass('share_with_me');
			actionDiv.hide('fast');
			$('.agent_details_form .form_container .tabs').hide();
			$('.agent_details_form .form_container .tabs-content').hide();
			
			shareActionDiv.slideDown('slow');
			
			
			
		}else if(show_value == 1){
			$("#contact_action_div").data('hide', '0');			
			$("#share_friend span").html('SHARE WTIH A FRIEND');
			$('.agent_details_form').removeClass('share_with_me');	
			
			$('.agent_details_form .form_container .tabs').show();
			$('.agent_details_form .form_container .tabs-content').show();
			
			shareActionDiv.slideUp('fast');
			actionDiv.slideDown('slow');
		}
		
		
	});
	
	if($('#showing_form').length > 0){
			requestForm = $('#showing_form');
			requestValidator = requestForm.validate();
			requestForm.removeAttr("novalidate");
			requestFirstName 	= $('#RequestForm_firstName');
			requestLastName 	= $('#RequestForm_lastName');	
			requestEmail 	= $('#RequestForm_email');	
			requestTelephone		= $('#RequestForm_telephone');
			requestDateTime = $('#RequestForm_requestDateTime');
			requestComment = $('#RequestForm_comment');	
		
			requestFirstName.rules("add",{
				required:true,
				 messages: {
			            required: "Please Enter Your First Name"
			     }
			});
		
			requestLastName.rules("add",{
				required:true,
				 messages: {
			            required: "Please Enter Your Last Name"
			     }
			});
			
			requestEmail.rules("add",{
				required:true,
				 email:"required",
				 messages: {
			           email: "Please Enter A Valid Email Address",
			           required:"Please Enter Your Email Address"
			     }
			});
		
			requestTelephone.rules("add",{
				required:true,
				 messages: {
			            required: "Please Enter Your Telephone Number"
			     }
			});
		
			requestDateTime.rules("add",{
				required:true,
				 messages: {
			            required: "Please Enter Select A Date And Time"
			     }
			});
		
			requestComment.rules("add",{
				required:true,
				 messages: {
			            required: "Please Enter A Comment"
			     }
			});
			
			$(document).on('click', '#request_showing', function(e) {
					e.preventDefault();
					actionDiv.hide('fast');
			//		exposeMask.css({"position":"fixed", "top":"0","left": "0","width": "100%","height": "100%", "display": "block", "opacity":" 0.7", "z-index":"9998","background-color": "#002c48"});
			//		$('#request_showing').css({"z-index":"9999","position":"relative","color":"#fff"});
					showingActionDiv.show();
				});
				
				$(document).on('click', '#showing_submit', function(e) {
					if(requestForm.valid()){				
						sendContact('Request');
						e.preventDefault();
					}
				});	
				
	}
}


/******Variables && Methods Exclusive To The Agents Page******************/
var agentNameSearch = null,
	agentButton = null;

function configureAgents(){		
	$('body').removeClass('main_index');	
	getCurrentPage = $('#current_page').val();
	mainPropertyContainer = $('#main_agent_container');
	searchLoadingDiv = $('#loading');
	agentNameSearch = $('#agent_name_text');
	agentButton = $('#agent_submit');
	getAjaxRequest = "";
	pageUrl = ajaxLocation+"agents";
	configurePagination();

	agentButton.click(function(){
		getAjaxRequest = agentsPagination(1,getAjaxRequest,false);
	});
	
}

function agentsPagination(currentPage,ajaxRequest,popState){
	searchStartStopLoading(true);
	if(ajaxRequest !== ""){
		ajaxRequest.abort();
	}
	var agentsUrl = ajaxLocation+"ajax-agent";
	var url = pageUrl;
	if(agentNameSearch.val() !== ""){
		agentsUrl += "/"+agentNameSearch.val();
		url += "/"+agentNameSearch.val();
	}
	agentsUrl += "/page/"+currentPage	
	url += "/page/"+currentPage;
	
	ajaxRequest = 	$.ajax({
						type:'GET',
						url: agentsUrl,
						dataType: "html"
					});
	ajaxRequest.then(
		function(data){			
			mainPropertyContainer.html(data);		
			configurePagination();			
			searchStartStopLoading(false);
			pageLoaded = true;	
			if(!popState){
				history.pushState(url, "Agents", url);			
			}
			popStateCheck = 1;			
			$(document).foundation();
			
		},
		function(data){
		}
	); 
	return ajaxRequest;
}

function popStateAgentPage(stateUrl){	
	if(stateUrl !== null){
		var urlSegments = stateUrl.split("agents");
		var pageNumber  = 0;
		var searchField = "";
		if(urlSegments[1].indexOf("/page/") === 2){
			searchField = urlSegments[1].split("/page/");			
			searchField = searchField[0];
			searchField = searchField.split("/");			
			if(agentNameSearch.val() === undefined){
				agentNameSearch = $('#agent_name_text');
			}
			agentNameSearch.val(searchField[1]);
		}
		if(urlSegments[1].indexOf("/page/") !== -1){
			pageNumber = urlSegments[1].split("/page/");
			pageNumber = pageNumber[1];
			pageNumber = pageNumber.split("/");
			pageNumber = pageNumber[0];
		}				
		if(popStateCheck === 1){
			getAjaxRequest = agentsPagination(pageNumber,getAjaxRequest,true);				
		}
		popStateCheck = 1;	
	}
	else{		
		getAjaxRequest = agentsPagination(1,getAjaxRequest,true);
	}
}
/*************************************************************************/

/********Variables && Methods Exclusive To The Search Page ***************/
var searchFieldHidden = null,
	priceFromHidden = null,
	priceToHidden = null,
	rentSaleHidden = null,
	currencyHidden = null,
	bedHidden = null,
	bathHidden = null,
	propertyTypeHidden = null,
	categoryHidden = null,
	similarHidden = null,
	previewHidden = null,
	mainPropertyContainer = null,		
	searchLimit = null,
	searchLimitSelect = null,
	searchRangeSelect = null,	
	searchRange = null,
	searchAreaFilter = null,
	searchLoadingDiv = null,	
	searchListingActivity = null,
	searchListingAmmenities = null,
	searchAgentSlug = null,
	searchNextLink = null,
	searchPreviousLink = null,
	searchListingTypes = null,
	searchCurrencyList = null,
	searchUSD = null,
	searchJMD = null,
	searchCAD = null,
	searchEUR = null,
	searchGBP = null,
	searchUSDSymbol = null,
	searchJMDSymbol = null,
	searchCADSymbol = null,
	searchEURSymbol = null,
	searchGBPSymbol = null;

/*************************************************************************/
/*Configures the property search page of the application. 				 */
/*************************************************************************/
function configureSearch(){	
	pageUrl = ajaxLocation+"search";
	searchLimitSelect = $('#limit');
	searchRangeSelect = $('#price_order');
	searchAreaFilter = $('#area_found');	
	getCurrentPage = $('#current_page').val();
	searchLimit = 15;
	searchRange = "H";
	getAjaxRequest = "";
	searchLoadingDiv = $('#loading');	
	searchFieldHidden = $('#search_field_hidden');
	priceFromHidden = $('#price_from_hidden');
	priceToHidden = $('#price_to_hidden');
	rentSaleHidden = $('#rentsale_hidden');

	parishHidden = $('#parish_field_hidden');
	townHidden = $('#town_field_hidden');
	
	currencyHidden =$('#currency_hidden');	
	previewHidden = $('#preview_hidden');
	bedHidden =$('#bed_hidden');
	bathHidden =$('#bath_hidden');
	propertyTypeHidden = $('#property_type_hidden');
	categoryHidden = $('#category_hidden');
	similarHidden =$('#similar_hidden');
	mainPropertyContainer = $('#main_property_container');
	searchListingActivity = $('input[name="price_reduced[]"]');
	searchListingAmmenities = $('input[name="ammenities[]"]');
	searchListingTypes = $('input[name="property_type[]"]');	
	searchAgentSlug = $('#agent_slug');
	searchAgentId 				= $('#agent_id');
	searchCurrencyList = $('#currency_list');
	searchNextLink = $('#next_link');
	searchPreviousLink = $('#previous_link');

	searchUSD = $('#USD');
	searchJMD = $('#JMD');
	searchCAD = $('#CAD');
	searchEUR = $('#EUR');
	searchGBP = $('#GBP');

	searchUSDSymbol = $('#symbol_USD');
	searchJMDSymbol = $('#symbol_JMD');
	searchCADSymbol = $('#symbol_CAD');
	searchEURSymbol = $('#symbol_EUR');
	searchGBPSymbol = $('#symbol_GBP');
	configurePagination();

	if(priceFromHidden.val() != "" && priceToHidden.val() != ""){
		var priceRangeSelectString = parseFloat(priceFromHidden.val())+'-'+parseFloat(priceToHidden.val());
		priceRangeSelectString = priceRangeSelectString.replace(",", "");

	}else{
		priceRangeSelectString = "";
	}

	priceRangeSelect.val(priceRangeSelectString);

	searchLimitSelect.change(function(){
		searchLimit = parseInt(searchLimitSelect.val());
		searchRange = searchRangeSelect.val();
		getCurrentPage = 1;		
		getAjaxRequest = propertySearchRefine(searchLimit,searchRange,getCurrentPage,getAjaxRequest,false);
	});

	searchRangeSelect.change(function(){		
		if( parseInt(searchLimit) !== parseInt(searchLimitSelect.val())){
			searchLimit = parseInt(searchLimitSelect.val());
			getCurrentPage = 1;
		}				
		searchRange = searchRangeSelect.val();				
		getAjaxRequest = propertySearchRefine(searchLimit,searchRange,getCurrentPage,getAjaxRequest,false);
	});

	searchListingActivity.click(function(){
		searchLimit = parseInt(searchLimitSelect.val());
		searchRange = searchRangeSelect.val();
		getCurrentPage = 1;
		getAjaxRequest = propertySearchRefine(searchLimit,searchRange,getCurrentPage,getAjaxRequest,false);
	});

	searchListingAmmenities.click(function(){
		searchLimit = parseInt(searchLimitSelect.val());
		searchRange = searchRangeSelect.val();
		getCurrentPage = 1;
		getAjaxRequest = propertySearchRefine(searchLimit,searchRange,getCurrentPage,getAjaxRequest,false);
	});

	searchListingTypes.click(function(){		
		searchLimit = parseInt(searchLimitSelect.val());
		searchRange = searchRangeSelect.val();
		getCurrentPage = 1;
		getAjaxRequest = propertySearchRefine(searchLimit,searchRange,getCurrentPage,getAjaxRequest,false);
		
	});

	searchNextLink.click(function(e){
		e.preventDefault();
		searchLimit = parseInt(searchLimitSelect.val());
		searchRange = searchRangeSelect.val();		
		if(getCurrentPage !== parseInt($('input[name="number_pages"]').val())){
			getCurrentPage++;
		} 
		getAjaxRequest = propertySearchRefine(searchLimit,searchRange,getCurrentPage,getAjaxRequest,false);
	});
	searchPreviousLink.click(function(e){
		e.preventDefault();
		searchRange = searchRangeSelect.val();
		searchLimit = parseInt(searchLimitSelect.val());
		if(getCurrentPage !== 1 && getCurrentPage !== 0){
			getCurrentPage--;
		} 
		getAjaxRequest = propertySearchRefine(searchLimit,searchRange,getCurrentPage,getAjaxRequest,false);
	});

	searchAreaFilter.change(function(){
		searchRange = searchRangeSelect.val();
		searchLimit = parseInt(searchLimitSelect.val());
		getCurrentPage = 1; 		
		getAjaxRequest = propertySearchRefine(searchLimit,searchRange,getCurrentPage,getAjaxRequest,false);		
	});

	searchCurrencyList.change(function(){		
		var listThis = $(this);
		if(listThis.val() !== ""){
			listThis.find('[value=""]').remove();			
			$('.property-item').each(function(){
				var itemThis = $(this);
				var price = Math.round(parseInt(itemThis.data('jmd-price')) / parseInt(window["search"+listThis.val()].val()));
				price = price.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
				price = price.replace(/\.?0+$/, '');
				$('#price_'+itemThis.data('property-item-id')).html(window["search"+listThis.val()+"Symbol"].val()+' '+price);				
			});	
		}			
	});
	
	
	if($(".recent_listings_agent").length > 0){
		$(".recent_listings_agent").bxSlider({
			auto: true,
			controls:true,
			speed:1600,
			pause:9000,
			pager: false,
			minSlides: 3,
			maxSlides: 3,
			slideWidth: 245,
			slideMargin: 5,
			autoHover: true,
			nextSelector: '.recent_next_click',
			prevSelector: '.recent_previous_click',
			nextText: 'Onward →',
			prevText: '← Go back'
		});
	}
	
	
}
/**************************************************************************/
/*This method manages the onpopstate for the property search page in      */
/*regards to page navigation. It will dissassemble the state (url) 		  */
/*received from the onpopstate method to appropriately reconfigure search */ 
/*parameters.															  */
/**************************************************************************/
function popStateSearchPage(stateUrl) {		
	if(stateUrl !== null){
		var urlSegments = stateUrl.split("search");	
		var pageNumber  = 0;
		var searchField  = "";
		var fromField = "";
		var toField = "";
		var rentSaleField = "";
		var currencyField = "";
		var propertyTypeField = "";
		var bedField = "";
		var bathField = "";
		var categoryField = "";
		var similarField = "";
		var agentField = "";
		var townField = "";
		var parishField = "";

		var limitField = 20;
		var rangeField = 20;

		if(urlSegments[1].indexOf("/agent/") !== -1){
			agentField = urlSegments[1].split("/agent/");
			agentField = agentField[1];
			agentField = agentField.split("/");
			agentField = agentField[0].split("-");
			searchAgentSlug.val(agentField[0]);			
			searchAgentId.val(agentField[1]);	
		}	
		if(urlSegments[1].indexOf("/page/") !== -1){
			pageNumber = urlSegments[1].split("/page/");
			pageNumber = pageNumber[1];
			pageNumber = pageNumber.split("/");
			pageNumber = pageNumber[0];
		}	
		if(urlSegments[1].indexOf("/parish/") !== -1){
			parishField = urlSegments[1].split("/parish/");
			parishField = parishField[1];
			parishField = parishField.split("/");		
			parishHidden.val(parishField[0]);	
		}
		if(urlSegments[1].indexOf("/town/") !== -1){
			parishField = urlSegments[1].split("/town/");
			parishField = parishField[1];
			parishField = parishField.split("/");		
			townHidden.val(parishField[0]);	
		}
		if(urlSegments[1].indexOf("/field/") !== -1){
			searchField = urlSegments[1].split("/field/");
			searchField = searchField[1];
			searchField = searchField.split("/");		
			searchFieldHidden.val(searchField[0]);	
		}
		if(urlSegments[1].indexOf("/from/") !== -1){
			fromField = urlSegments[1].split("/from/");
			fromField = fromField[1];
			fromField = fromField.split("/");	
			priceFromHidden.val(fromField[0]);		
		}
		if(urlSegments[1].indexOf("/to/") !== -1){
			toField = urlSegments[1].split("/to/");
			toField = toField[1];
			toField = toField.split("/");
			priceToHidden.val(toField[0]);
		}
		if(urlSegments[1].indexOf("/rentsale/") !== -1){
			rentSaleField = urlSegments[1].split("/rentsale/");
			rentSaleField = rentSaleField[1];
			rentSaleField = rentSaleField.split("/");
			rentSaleHidden.val(rentSaleField[0]);		
		}
		if(urlSegments[1].indexOf("/limit/") !== -1){
			limitField = urlSegments[1].split("/limit/");
			limitField = limitField[1];
			limitField = limitField.split("/");
			limitField = limitField[0];		
		}
		if(urlSegments[1].indexOf("/range/") !== -1){
			rangeField = urlSegments[1].split("/range/");
			rangeField = rangeField[1];
			rangeField = rangeField.split("/");
			rangeField = rangeField[0];		
		}
		if(urlSegments[1].indexOf("/currency/") !== -1){
			currencyField = urlSegments[1].split("/currency/");
			currencyField = currencyField[1];
			currencyField = currencyField.split("/");
			currencyHidden.val(currencyField[0]);				
		}
		if(urlSegments[1].indexOf("/bed/") !== -1){
			bedField = urlSegments[1].split("/bed/");
			bedField = bedField[1];
			bedField = bedField.split("/");
			bedHidden.val(bedField[0]);				
		}
		if(urlSegments[1].indexOf("/bath/") !== -1){
			bathField = urlSegments[1].split("/bath/");
			bathField = bathField[1];
			bathField = bathField.split("/");
			bathHidden.val(bathField[0]);				
		}
		if(urlSegments[1].indexOf("/propertytype/") !== -1){
			propertyTypeField = urlSegments[1].split("/propertytype/");
			propertyTypeField = propertyTypeField[1];
			propertyTypeField = propertyTypeField.split("/");
			propertyTypeHidden.val(propertyTypeField[0]);				
		}
		if(urlSegments[1].indexOf("/category/") !== -1){
			categoryField = urlSegments[1].split("/category/");
			categoryField = categoryField[1];
			categoryField = categoryField.split("/");
			categoryHidden.val(categoryField[0]);				
		}
		if(urlSegments[1].indexOf("/similar/") !== -1){
			similarField = urlSegments[1].split("/similar/");
			similarField = similarField[1];
			similarField = similarField.split("/");
			similarHidden.val(similarField[0]);				
		}

		if(popStateCheck === 1){					
			getAjaxRequest = propertySearchRefine(limitField,rangeField,pageNumber,getAjaxRequest,true);
		}
		popStateCheck = 1;
	}
	else{		
		getAjaxRequest = propertySearchRefine(searchLimitSelect.val(),searchRangeSelect.val(),1,getAjaxRequest,true);
	}	
}

/**************************************************************************/
/*This method manages the ajax search refinement queries made by the user.*/
/**************************************************************************/
function propertySearchRefine(limitParam, rangeParam, currentPage, ajaxRequest, popState){

	searchStartStopLoading(true);
	
    if(ajaxRequest !== ""){
		ajaxRequest.abort();
	}
    
	var url = "",
		searchUrl = "",
		listingActivity = "",
		listingAmmenities = "",
		listingTypes = "";

	if(previewHidden.val()!== undefined && previewHidden.val().trim() !== ""){
		url = searchUrl += "/" + previewHidden.val();
	}

	if(categoryHidden.val()!== undefined && categoryHidden.val().trim() !== ""){
		url = searchUrl += "/" + categoryHidden.val();
	}
		
	if(rentSaleHidden.val() !== undefined && rentSaleHidden.val().trim() !== "" && rentSaleHidden.val().trim() !== "both"){
		url = searchUrl += "/" + rentSaleHidden.val();
	}

	if(searchAgentSlug.val()!== undefined && searchAgentId.val() !== undefined){
		url = searchUrl += "/agent/" + searchAgentSlug.val() + "-" + searchAgentId.val();
	}
    
    if(searchFieldHidden.val() !== undefined && searchFieldHidden.val().trim() !== ""){
		url = searchUrl += "/keywords/" + searchFieldHidden.val().replace(" ", "+");			
	}
    if(parishHidden.val() !== undefined && parishHidden.val().trim() !== ""){
		url = searchUrl += "/parish/" + parishHidden.val().replace(" ", "+");			
	}
	if(townHidden.val() !== undefined && townHidden.val().trim() !== ""){
		url = searchUrl += "/town/" + townHidden.val().replace(" ", "+");			
	}

	if(bedHidden.val() !== undefined && bedHidden.val().trim() !== ""){
		url = searchUrl += "/bed/" + bedHidden.val();			
	}
    
	if(bathHidden.val() !== undefined && bathHidden.val().trim() !== ""){
		url = searchUrl += "/bath/" + bathHidden.val();			
	}
    
	if(priceFromHidden.val() !== undefined && priceFromHidden.val().trim() !== ""){
		url = searchUrl += "/price_from/" + priceFromHidden.val();		
	}
    
	if(priceToHidden.val() !== undefined && priceToHidden.val().trim() !== ""){
		url = searchUrl += "/price_to/" + priceToHidden.val();
	}
    
	if(currencyHidden.val() !== undefined && currencyHidden.val().trim() !== ""){
		url = searchUrl += "/currency/" + currencyHidden.val();			
	}	
	
	if(propertyTypeHidden.val() !== undefined && propertyTypeHidden.val().trim() !== ""){
		url = searchUrl += "/property_type/" + propertyTypeHidden.val();			
	}

	if(parseInt(currentPage) > 0){
		url = searchUrl += "/page/" + currentPage;
	}
    
	
    
    if(similarHidden.val() !== undefined && similarHidden.val().trim() !== ""){
		url = searchUrl += "/similar/" + similarHidden.val();		
	}
    
    url = searchUrl += "/limit/" + limitParam + "/range/" + rangeParam;

	searchListingActivity.each(function(){
		var thisElement = $(this);
		if(thisElement.is(':checked')){
			listingActivity += thisElement.val() +"-";
		}		
	});
    
    if(listingActivity !== ""){
		listingActivity = listingActivity.substring(0, listingActivity.length - 1);
		searchUrl += "/status/" + listingActivity;
	}

	searchListingAmmenities.each(function(){
		var thisElement = $(this);
		if(thisElement.is(':checked')){
			listingAmmenities += thisElement.val() + "-";
		}
	});

	searchListingTypes.each(function(){
		var thisElement = $(this);
		if(thisElement.is(':checked')){
			listingTypes += thisElement.val() + "-";
		}
	});

	if(listingAmmenities !== ""){
		listingAmmenities = listingAmmenities.substring(0, listingAmmenities.length - 1);
		searchUrl += "/ammenities/" + listingAmmenities;
	}

	if(listingTypes !== ""){
		listingTypes = listingTypes.substring(0, listingTypes.length - 1);
		searchUrl += "/property_type/" + listingTypes;
	}
    
	if(searchAreaFilter.val() !== undefined && searchAreaFilter.val().trim() !== ""){
		searchUrl += "/area/" + searchAreaFilter.val();
	}
    
    var fullSearchUrl = ajaxLocation + "search" + searchUrl,
    	fullPageUrl = pageUrl + url;
    
    ajaxRequest = 	$.ajax({
						type:'GET',
						url: fullSearchUrl,
						dataType: "html"
					});
	
    ajaxRequest.then(
		function(data){			
			mainPropertyContainer.html(data);
			configurePagination();
			searchStartStopLoading(false);
			
			$('html,body').animate({scrollTop: $('body').offset().top}, 500);
			
			searchPopStateCheck = 1;
			pageLoaded = true;	
			
            if(!popState){
				history.pushState(fullPageUrl, "Property Search", fullPageUrl);			
			}
            
            /* update property count */
            var totalResults = $("#total_results_hidden").val();
            $("#total_results").text(totalResults);
            searchCurrencyList.change();
            
            $(document).foundation();
		},
		function(data){
		}
	);    
	return ajaxRequest;
}


function callVideo() {
	// 2. This code loads the IFrame Player API code asynchronously.
	var tag = document.createElement('script');
	tag.src = "//www.youtube.com/iframe_api";
	var firstScriptTag = document.getElementsByTagName('script')[0];
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}
		

      // 3. This function creates an <iframe> (and YouTube player)
      //    after the API code downloads.
      var player;
      function onYouTubeIframeAPIReady() {
      	var video_prop_id = $('input[name="video_id"]').val();
      	
      	player = new YT.Player('player', {
      		height: '462',
      		width: '640',
      		videoId: video_prop_id,
      		events: {
      			'onReady': onPlayerReady,
      			'onStateChange': onPlayerStateChange
      		}
      	});
      }

      // 4. The API will call this function when the video player is ready.
      function onPlayerReady(event) {
        event.target.playVideo();
      }

      // 5. The API calls this function when the player's state changes.
      //    The function indicates that when playing a video (state=1),
      //    the player should play for six seconds and then stop.
      var done = false;
      function onPlayerStateChange(event) {
        if (event.data == YT.PlayerState.PLAYING && !done) {
          //setTimeout(stopVideo, 6000);
          done = true;
        }
      }
      function stopVideo() {
        player.stopVideo();
      }
/*************************************************************************/

var loginEmail = null,
	loginPassword = null,
	loginSubmit = null,	
	registerForm = null;

function configureMembers (){
	mainContainer = $('#member_div');
	localStorage.setItem('apiMember', null);
	loginEmail = $('#LoginForm_email');
	loginPassword = $('#LoginForm_password');
	loginSubmit = $('#submit_login_request');
	loginForm 		= $('#login-form');
	validator = loginForm.validate();	

	loginEmail.rules("add",{
		required:true,
		 email:"required",
		 messages: {
	           email: "Please Enter A Valid Email Address",
	           required:"Please Enter Your Email Address"
	     }
	});

	loginPassword.rules("add",{
		required:true,
		 messages: {
                required: "Please Enter Your Password."
         }
	});

	loginSubmit.click(function(e){
		if(loginForm.valid()){			
			
		}
	});
	configureFacebookAPI();	
}

var registerTitle = null,
	registerFirstName = null,
	registerLastName = null,
	registerEmail = null,
	registerPassword = null,
	registerConfirmPassword = null,
	registerSubmit = null,
	registerForm = null,
	focusOutClass = null,
	getDuplicateAjaxRequest,
	duplicateCheck = null,
	mailingList = null,	
	mailingDiv = null,
	propertyTypes = null,
	mailingListTenure = null,
	messsageSpan;
function configureSignUp(){
	registerTitle = $('#RegisterForm_title');
	registerFirstName = $('#RegisterForm_firstName');
	registerLastName = $('#RegisterForm_lastName');
	registerEmail = $('#RegisterForm_email');
	registerPassword = $('#RegisterForm_password');
	registerConfirmPassword = $('#RegisterForm_confirmPassword');
	registerSubmit = $('#submit_register_request');
	registerForm = $('#register-form');
	focusOutClass = $('.focus_out');
	getDuplicateAjaxRequest = "";
	duplicateCheck = $('#duplicate_check');
	messsageSpan = $('#messsage_span');
	
	validator = registerForm.validate();

	registerEmail.rules("add",{
		required:true,
		email:"required",
		messages: {
			email: "Please Enter A Valid Email Address",
			required:"Please Enter Your Email Address"
		}
	});

	registerTitle.rules("add",{
		required:true,
		messages: {
			required: "Please Select a Title"
		}
	});

	registerFirstName.rules("add",{
		required:true,
		messages: {
			required: "Please Enter Your First Name"
		}
	});

	registerLastName.rules("add",{
		required:true,
		messages: {
			required: "Please Enter Your Last Name"
		}
	});	

	registerPassword.rules("add",{
		required:true,
		messages: {
			required: "Please Enter A Password."
		}
	});	

	registerConfirmPassword.rules("add",{
		required:true,
		equalTo:registerPassword,
		messages: {
			equalTo: "Password and Confirm Password Are Not The Same",
			required: "Please Enter Your Password Confirmation",
		}
	});	

	registerSubmit.click(function(e){
		if(registerForm.valid()){
			if(duplicateCheck.val() !== "1"){
				e.preventDefault();
			}
		}
	});

	focusOutClass.focusout(function(){
		getDuplicateUser();
	});	
}

function getDuplicateUser(){
	if( registerEmail.val() !== "" && registerFirstName.val() !== "" && registerLastName.val() !== "" ){
		if(getDuplicateAjaxRequest !== ""){
			getDuplicateAjaxRequest.abort()
		}
		var duplicateUrl = window.location.href+"/"+registerEmail.val()+"/"+registerFirstName.val()+"/"+registerLastName.val();		
		getDuplicateAjaxRequest = $.ajax({
								type:'GET',
								url: duplicateUrl,
								dataType: "json",								
								}
							);
		getDuplicateAjaxRequest.then(
			
			function(data){		
				data = JSON.parse(data);	
				
				if( parseInt(data.status) === 201){
					duplicateCheck.val("1");
					console.log(data);
					messsageSpan.html("");
					messsageSpan.removeClass('show');
					messsageSpan.hide();
				}
				else{					
					duplicateCheck.val(data.status);
					messsageSpan.html(data.message);					
					if(data.status != null){
						messsageSpan.addClass('error-alert');
						messsageSpan.addClass('show');
						messsageSpan.show();
					}				
				}	
			},
			function(data){
				
			}
		);		
	}	
}

function configureEditProfile(){
	registerTitle = $('#ProfileForm_title');
	registerFirstName = $('#ProfileForm_firstName');
	registerLastName = $('#ProfileForm_lastName');
	registerEmail = $('#ProfileForm_email');
	registerPassword = $('#ProfileForm_password');
	registerConfirmPassword = $('#ProfileForm_confirmPassword');
	registerSubmit = $('#complete_submit');
	registerForm = $('#list-property-form');
	focusOutClass = $('.focus_out');
	getDuplicateAjaxRequest = "";
	duplicateCheck = $('#duplicate_check');
	messsageSpan = $('#messsage_span');
	mailingList = $("input[name='ProfileForm[mailingList]']");
	propertyTypes = $("input[name='ProfileForm[propertyType][]']");
	mailingListTenure = $("input[name='ProfileForm[mailingListTenure]']")
	mailingDiv = $('#mailing_div');

	validator = registerForm.validate();

	registerEmail.rules("add",{
		required:true,
		email:"required",
		messages: {
			email: "Please Enter A Valid Email Address",
			required:"Please Enter Your Email Address"
		}
	});

	registerTitle.rules("add",{
		required:true,
		messages: {
			required: "Please Select a Title"
		}
	});

	registerFirstName.rules("add",{
		required:true,
		messages: {
			required: "Please Enter Your First Name"
		}
	});

	registerLastName.rules("add",{
		required:true,
		messages: {
			required: "Please Enter Your Last Name"
		}
	});

	registerConfirmPassword.rules("add",{
		equalTo:registerPassword,
		messages: {
			equalTo: "Password and Confirm Password Are Not The Same"
			
		}
	});	

	registerSubmit.click(function(e){
		if(registerForm.valid()){			
			if(duplicateCheck.val() === "2"){
				e.preventDefault();
			}
		}
	});

	focusOutClass.focusout(function(){
		getDuplicateUser();
	});	
	mailingList.change(function(){
		if(mailingList.is(':checked')) {
			var selected = $(this);
			if(selected.val() === "Y"){
				mailingDiv.show();
			}
			else if(selected.val() === "N"){
				mailingListTenure.prop("checked", false);
				propertyTypes.prop('checked', false);
				mailingDiv.hide();	
			}	
		}		
	});
}

function configureForgetPassword(){
	loginEmail = $('#ForgetForm_email');
	loginSubmit = $('#submit_forget_request');
	loginForm 		= $('#forget-form');
	validator = loginForm.validate();

	loginEmail.rules("add",{
		required:true,
		 email:"required",
		 messages: {
	           email: "Please Enter A Valid Email Address",
	           required:"Please Enter Your Email Address"
	     }
	});

	loginSubmit.click(function(e){
		if(loginForm.valid()){			
			
		}
	});
}

function configureResetPassword(){
	registerPassword = $('#ResetForm_password');
	registerConfirmPassword = $('#ResetForm_confirmPassword');	
	loginSubmit = $('#submit_reset_request');
	loginForm 		= $('#reset-form');
	validator = loginForm.validate();

	registerPassword.rules("add",{
		required:true,
		messages: {
			required: "Please Enter A Password."
		}
	});	

	registerConfirmPassword.rules("add",{
		required:true,
		equalTo:registerPassword,
		messages: {
			equalTo: "Password and Confirm Password Are Not The Same",
			required: "Please Enter Your Password Confirmation",
		}
	});

	loginSubmit.click(function(e){
		if(loginForm.valid()){			
			
		}
	});
}

var removeClass = null;

function configureFavouriteProperties(){	
	mainPropertyContainer = $('#main_property_container');
	searchLoadingDiv = $('#loading');
	
	removeClass  = $('.remove_property');
	getCurrentPage = parseInt($('#current_page').val());	
	getAjaxRequest = "";
	
	configurePagination();
	pageUrl = ajaxLocation+"favourites";
	$(document).on('click', '.remove_property', function(e) {
		e.preventDefault();
		var currentRemove = $(this);
		var propertyId = currentRemove.data("property-id");
		var mlsFound = currentRemove.data("mls");
		$('#modal_header').html("Confirm");
		$('#modal_paragraph').html("Are You Sure You Want To Remove This Favourite?");
		$('#modal_button').hide();
		$('#modal_button_yes').show();
		$('#modal_button_no').show();	
		
		$('#favourites_modal').foundation('reveal', 'open');
		$('html,body').animate({scrollTop: $('body').offset().top}, 500);
		
		$('#modal_button_yes').click(function(){
			//$('#favourites_modal').foundation('reveal', 'close');
			var favouriteUrl = ajaxLocation+"ajax-favourite/property_id/"+propertyId+"/mls/"+mlsFound+"/saved/1";
			savePropertyAjaxRequest = $.ajax({
								type:'POST',
								url: favouriteUrl,
								dataType: "json",									
							}
			);
			savePropertyAjaxRequest.then(
				function(data){					
					if(data.status === "200"){				
						getAjaxRequest = 	favouritePagination(1,getAjaxRequest,false);						
						$('#modal_header').html("Removed");
						$('#modal_paragraph').html("Favourite Removed");
						$('#modal_button').show();
						$('#modal_button_yes').hide();
						$('#modal_button_no').hide();
					}
					else{
						$('#modal_header').html("Error");
						$('#modal_paragraph').html("Favourite Was Not Removed");
						$('#modal_button').show();
						$('#modal_button_yes').hide();
						$('#modal_button_no').hide();
					}		
					searchStartStopLoading(false);
					$('#favourites_modal').foundation('reveal', 'open');	
					$('html,body').animate({scrollTop: $('body').offset().top}, 500);									
				},
				function(data){					
					$('#modal_header').html("Error");
					$('#modal_paragraph').html("Favourite Was Not Removed");
					$('#modal_button').show();
					$('#modal_button_yes').hide();
					$('#modal_button_no').hide();
					searchStartStopLoading(false);
					$('#favourites_modal').foundation('reveal', 'open');
					$('html,body').animate({scrollTop: $('body').offset().top}, 500);
				}
			);
			
		});
		$('#modal_button_no').click(function(){
			propertyId = "";
			mlsFound = "";
			$('#favourites_modal').foundation('reveal', 'close');
			$('.reveal-modal-bg').hide();
		});
		$('#modal_button').click(function(){
			$('#favourites_modal').foundation('reveal', 'close');
			$('.reveal-modal-bg').hide();
		});		
	});
}

function favouritePagination(currentPage,ajaxRequest,popState){
	searchStartStopLoading(true);
	if(ajaxRequest !== ""){
		ajaxRequest.abort();
	}
	var favouriteUrl = ajaxLocation+"favourites/page/"+currentPage;
	var url = pageUrl+"/page/"+currentPage;
	ajaxRequest = 	$.ajax({
						type:'GET',
						url: favouriteUrl,
						dataType: "html"
					});
	ajaxRequest.then(
		function(data){			
			mainPropertyContainer.html(data);
			configurePagination();
			searchStartStopLoading(false);			
			pageLoaded = true;	
			if(!popState){
				history.pushState(url, "Favourite Properties", url);			
			}
			popStateCheck = 1;				
			var totalResults = $("#total_results_hidden").val();
            $("#total_results").text(totalResults);             
            $(document).foundation();
		},
		function(data){
		}
	); 
	return ajaxRequest;
}

function popStateFavouritePage(stateUrl){	
	if(stateUrl !== null){
		var urlSegments = stateUrl.split("favourites");
		var pageNumber  = 0;
		if(urlSegments[1].indexOf("/page/") !== -1){
			pageNumber = urlSegments[1].split("/page/");
			pageNumber = pageNumber[1];
			pageNumber = pageNumber.split("/");
			pageNumber = pageNumber[0];
		}		
		if(popStateCheck === 1){
			getAjaxRequest = favouritePagination(pageNumber,getAjaxRequest,true);				
		}
		popStateCheck = 1;	
	}
	else{		
		getAjaxRequest = favouritePagination(1,getAjaxRequest,true);
	}
}

var projectNameHidden = null;

function configureProjects(){	
	projectNameHidden = $('#project_hidden_name');
	mainPropertyContainer = $('#main_property_container');	
	getCurrentPage = parseInt($('#current_page').val());	
	getAjaxRequest = "";
	searchLoadingDiv = $('#loading');
	searchNextLink = $('#next_link');
	searchPreviousLink = $('#previous_link');
	configurePagination();	

	searchNextLink.click(function(e){
		e.preventDefault();		
		if(getCurrentPage !== parseInt($('input[name="number_pages"]').val())){
			getCurrentPage++;
		} 
		getAjaxRequest = projectsPagination(getCurrentPage,getAjaxRequest,true);
	});
	searchPreviousLink.click(function(e){
		e.preventDefault();		
		if(getCurrentPage !== 1 && getCurrentPage !== 0){
			getCurrentPage--;
		} 
		getAjaxRequest = projectsPagination(getCurrentPage,getAjaxRequest,true);
	});

}

function projectsPagination(currentPage,ajaxRequest,popState){
	searchStartStopLoading(true);
	if(ajaxRequest !== ""){
		ajaxRequest.abort();
	}
	var projectUrl = ajaxLocation+"project/"+projectNameHidden.val()+"/page/"+currentPage;
	var url = projectUrl;
	ajaxRequest = 	$.ajax({
						type:'GET',
						url: projectUrl,
						dataType: "html"
					});
	ajaxRequest.then(
		function(data){			
			mainPropertyContainer.html(data);			
			configurePagination();
			searchStartStopLoading(false);			
			pageLoaded = true;	
			if(!popState){
				history.pushState(url, "Project Listings", url);			
			}
			popStateCheck = 1;				
			var totalResults = $("#total_results_hidden").val();
            $("#total_results").text(totalResults);             
            $(document).foundation();
		},
		function(data){
		}
	); 
	return ajaxRequest;
}

function popStateProjectsPage(stateUrl){	
	if(stateUrl !== null){
		var urlSegments = stateUrl.split("favourites");
		var pageNumber  = 0;
		if(urlSegments[1].indexOf("/page/") !== -1){
			pageNumber = urlSegments[1].split("/page/");
			pageNumber = pageNumber[1];
			pageNumber = pageNumber.split("/");
			pageNumber = pageNumber[0];
		}		
		if(popStateCheck === 1){
			getAjaxRequest = projectsPagination(pageNumber,getAjaxRequest,true);				
		}
		popStateCheck = 1;	
	}
	else{		
		getAjaxRequest = projectsPagination(1,getAjaxRequest,true);
	}
}


/*************************************************************************/
/*Facebook API Membership Login 										 */
/*************************************************************************/

function configureFacebookAPI(){

	(function(d, s, id) {
		var js, fjs = d.getElementsByTagName(s)[0];
		if (d.getElementById(id)) return;
		js = d.createElement(s); js.id = id;
		js.src = "//connect.facebook.net/en_US/sdk.js";
		fjs.parentNode.insertBefore(js, fjs);
	}(document, 'script', 'facebook-jssdk'));

	window.fbAsyncInit = function() {
		FB.init({
			appId      : '261922160663582',  
			cookie     : true,  // enable cookies to allow the server to access 
			// the session
			xfbml      : true,  // parse social plugins on this page
			version    : 'v2.0' // use version 2.0
		});
		// Now that we've initialized the JavaScript SDK, we call 
	  	// FB.getLoginStatus().  This function gets the state of the
	  	// person visiting this page and can return one of three states to
	  	// the callback you provide.  They can be:
	  	//
	  	// 1. Logged into your app ('connected')
	  	// 2. Logged into Facebook, but not your app ('not_authorized')
	  	// 3. Not logged into Facebook and can't tell if they are logged into
	  	//    your app or not.
	  	//
	  	// These three cases are handled in the callback function.

	  	/*FB.getLoginStatus(function(response) {	
	  		console.log(response);  	
	  		if(response.status === "connected"){
	  			statusChangeCallback(response);	  			
	  		}	  			  		
	  	});
		*/
	};
}

function statusChangeCallback(response) {	    
    // The response object is returned with a status field that lets the
    // app know the current login status of the person.
    // Full docs on the response object can be found in the documentation
    // for FB.getLoginStatus().
    if (response.status === 'connected') { 
    	facebookAPILogin();
    } 
    
}

// This function is called when someone finishes with the Login
// Button.  See the onlogin handler attached to it in the sample
// code below.
function checkLoginState() {	
	FB.getLoginStatus(function(response) {
		/*if(response.status === "connected"){
			document.getElementById('fb_test_spig').style.display = 'none';
		}*/		
		statusChangeCallback(response);
	});
}


// Here we run a very simple test of the Graph API after login is
// successful.  See statusChangeCallback() for when this call is made.
function facebookAPILogin() {		
	FB.api('/me', function(response) {
		if(!isEmptyObject(response)){			
			response.sent_api = "Facebook"; 
			var faceBookObject = response;
			localStorage.setItem('apiMember', JSON.stringify(faceBookObject));	
			apiLogin(response);			
		}				
	});
}

function faceBookLogin(){	
    FB.login(function(response) {    	
    	statusChangeCallback(response);    	
    },{scope: 'email'});
}

function googlePlusLogin(){
	gapi.auth.signIn({'clientid' : '1051244418980-08sfvu2pu5p3r87no4pndumkukdud0nn.apps.googleusercontent.com','cookiepolicy' : 'single_host_origin','callback' : 'googlePlusSignInCallBack','scope': 'https://www.googleapis.com/auth/plus.login email'});
}


function googlePlusSignInCallBack(authResult){	
	if (authResult['status']['signed_in']) {	

        $.ajax({
            type: "GET",
            url: "https://www.googleapis.com/oauth2/v2/userinfo?access_token="+authResult['access_token']
        }).done(function( data ){        	
        	var apiObject = {};
        	apiObject.first_name = data.given_name;
        	apiObject.last_name = data.family_name;
        	apiObject.email = data.email;
        	apiObject.sent_api = 'Google +';
        	localStorage.setItem('apiMember', JSON.stringify(apiObject));       	
			apiLogin(apiObject);
        });
    }
    else{
    	window.location.href = window.location;    	
    }	
}


/*******SITE VIDEO CHANNEL*******/

function loadVideo(playerUrl, autoplay) {
  swfobject.embedSWF(
      playerUrl + '&wmode=opaque&rel=0&border=0&fs=1&autoplay=' + 
      (autoplay?1:0), 'player', '640', '484', '9.0.0', false, 
      false, {allowfullscreen: 'true',wmode: 'opaque'});
}
function showMyVideos2(data) { 
  var feed = data.feed;
  var entries = feed.entry || [];
  var html = ['<div">'];
  //$('#print_r').dump(entries[0].media$group);
  entries = entries.sort(function() {return 0.5 - Math.random()}) 
  for (var i = 0; i < 1; i++) {
    var entry = entries[i];
    var description = entries[i].media$group.media$description.$t;
    var title = entry.title.$t.substr(0, 10);
    var thumbnailUrl = entries[i].media$group.media$thumbnail[0].url;
    var playerUrl = entries[i].media$group.media$content[0].url;
   	if(i==0){
    	var sep = 'firstvid';
   	}else{
   	   	var sep = '';
   	}
   	
   	if(i==2){
   		var sep = 'lastvid';
   	}
   	
    html.push('<div class="videogrid '+sep+'">', '<div class="img"><div class="vidoverlay"><a class="fancybox-media"  href="'+playerUrl+'" style="width: 100%; height: 100%; display:block"></a></div><img src="', 
              thumbnailUrl, '" width="286" height="190"/></div>', '</div>');
  }
  html.push('</div><br style="clear: left;"/>');
  document.getElementById('videos2').innerHTML = html.join('');
//  if (entries.length > 0) {
//    loadVideo(entries[0].media$group.media$content[0].url, false);
//  }


}

function configureUnsubscribe(){
	registerForm = $('#unsubscribe-form');	
	registerEmail = $('#SubscribeForm_email');
	registerSubscribe = $('#SubscribeForm_subscribeEmail');	
	registerSubmit = $('#submit_contact_request');
	shareValidator = registerForm.validate();

	registerEmail.rules("add",{
		required:true,
		equalTo:registerSubscribe,
		 email:"required",
		 messages: {
		    email: "Please Enter Email Address",
		    required:"Please Enter Your Email Address",
		    equalTo: "Please enter the correct email address"
		}
	});
}

function configureUpdateProfile(){	
	registerForm = $('#update-profile-form');	
	registerFirstName = $('#UpdateProfileForm_firstName');
	registerLastName = $('#UpdateProfileForm_lastName');
	registerEmail = $('#UpdateProfileForm_email');	
	registerSubmit = $('#submit_contact_request');
	shareValidator = registerForm.validate();

	registerEmail.rules("add",{
		required:true,		
		 email:"required",
		 messages: {
		    email: "Please Enter Email Address",
		    required:"Please Enter Your Email Address"		    
		}
	});

	registerFirstName.rules("add",{
		required:true,				 
		 messages: {		    
		    required:"Please Enter Your First Name"		    
		}
	});
	registerLastName.rules("add",{
		required:true,				 
		 messages: {		    
		    required:"Please Enter Your Last Name"		    
		}
	});
	
}

